.container {
  width: 100%;
  max-width: 800px;
}
.icon {
  fill: var(--secondary-blue);
  width: 17px;
  height: 17px;
  transform: rotate(90deg);
  margin-right: 0.8rem;
}
.text {
  color: var(--secondary-blue);
  font-size: var(--font-xm);
  font-weight: var(--w-bold);
}

.mobileGoBack {
  display: grid;
  place-items: center;
  padding: 1rem 1rem 1rem 0.5rem;
  .mobileIcon {
    width: 2rem;
    height: 2rem;
    transform: rotate(90deg);
  }
}
