.franklin {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.lucida {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.manrope {
  font-family: "Manrope", sans-serif;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.opensans {
  font-family: "Open Sans", sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.base-font {
  @extend .manrope;
  font-size: var(--font-m);
  font-weight: var(--w-regular);
  color: var(--main-black);
}
