.container {
	display: flex;
	flex-direction: column;
	background-color: var(--main-white);
	border-radius: var(--border-radius);
	padding: var(--spacing-extrasmall);
	margin: var(--spacing-extrasmall) 0 var(--spacing-extrasmall) 0;
	.img {
		margin-top: var(--spacing-extrasmall);
		border: 1px solid var(--color-item);
    border-radius: var(--border-radius);
	}
	.row {
		display: flex;
    justify-content: space-around;
		align-items: center;
    min-width: 100%;
	}
  .icon {
    width: 1.2rem;
    height: 1.2rem;
    fill: black;
    margin-left: 1rem;
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 835px) {
  .container{
    .row{
      display: none;
    }
  }
}