.headerCard{
  display: flex;
  width: 800px;
  align-items: center;
  .shareProfile{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin: 0px auto;
    border: 1px solid rgba(11, 26, 50, 0.2);
    border-radius: 20%;
    // padding: var(--spacing-small);
    padding: 0.8rem;
    svg{
      color: rgb(30, 136, 229);
      font-size: var(--font-l);
      font-weight: var(--w-regular);
    }
    a{
      color: rgb(30, 136, 229);
      font-size: var(--font-m);
      font-weight: var(--w-regular);
    }
  }
}
.headerCard div{
  width: fit-content;
}
.headerCard div:nth-child(2){
  margin-left: 2rem;
}
.serviceList {
  display: flex;
  flex-wrap: wrap;
}
.section {
  margin: 2.4rem 0;
}

.notAvailable {
  display: flex;
  align-items: center;
  max-width: 64rem;
  min-width: 80rem;
  margin-top: var(--spacing-medium);
  background: linear-gradient(
    271.68deg,
    rgba(255, 110, 93, 0.8) 1.64%,
    rgba(221, 39, 39, 0.8) 99.01%
  );
  svg {
    width: 10rem;
    margin-right: var(--spacing-small);
  }
  border-radius: 8px;
  padding: var(--spacing-medium);
  .row {
    display: flex;
    align-items: center;
  }
}
.closeCrossContainer {
  display: block;
  justify-content: flex-end;
  width: 100%;
  height: var(--spacing-extrasmall);
  .closeCross {
    // margin-top: var(--spacing-extrasmall);
    margin-left: var(--spacing-small);
    float: right;
  }
}
.icon {
  width: 1.2rem;
  height: 1.2rem;
  fill: black;
  margin-left: 1rem;
  transform: rotate(180deg);
}

.shareButtons {
  display: flex;
  flex-flow: row nowrap;
	div {
    margin-left: 2rem !important;
    width: 5rem !important;
    height: 5rem !important;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: space-evenly;
    // border: 1px solid blue;
    .iconCopy{
      width: 3rem;
      height: 3rem;
      cursor: pointer;
    }
    button{
      svg{
        width: 4rem;
      }
    }
    width: fit-content;
    height: fit-content;
  }
  div:nth-child(4){
    position: relative;
    border: 1px solid orange;
    border-radius: 50%;
    width: 3.8rem !important;
    height: 3.8rem !important;
    margin-top: 0.5rem;
    background-color: orange;
    color: white;
    .copySpan{
      position: absolute;
      top: 4.3rem;
      left: -8px;
      color: black;
      width: fit-content !important;
      background-color: #ccc;
      border-radius: 2rem;
      padding: 0.3rem 0.5rem 0.3rem 0.5rem;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 835px) {
  .profileContainer {
    padding: 0.8rem var(--spacing-small);
  }
  .section {
    margin: var(--spacing-small) 0;
    // max-width: 100vw;
  }
  .notAvailable {
    max-width: 91.5vw;
    min-width: 100%;
    flex-direction: column;
    .row {
      margin-bottom: calc(var(--spacing-small) * 2);
    }
    svg {
      width: 70px;
    }
  }
}
