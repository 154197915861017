.container {
  display: flex;
  position: relative;
  min-height: 100vh;
  .row {
    display: flex;
    align-items: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 772px;
    max-width: 772px;
    margin: 4.8rem auto;
  }
  .tabContent {
    width: 100%;
    margin-top: 3.2rem;
    .tabs {
      display: flex;
      align-items: center;
      height: 4.9rem;
      .tab {
        cursor: pointer;
        margin-right: 2.8rem;
        display: flex;
        align-items: center;
        p {
          font-size: var(--font-m);
          opacity: 0.3;
          display: block;
          position: relative;
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            width: 0px;
            height: 2px;
            background-color: var(--secondary-blue);
            transition: all 350ms ease-in-out;
          }
        }
        &.active {
          p {
            color: var(--secondary-blue);
            font-weight: var(--w-bold);
            opacity: 1;
            position: relative;
            &::after {
              width: 100%;
            }
          }
          .worksLength {
            background-color: var(--border-error);
          }
        }
      }
      .worksLength {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        background-color: rgba(11, 26, 50, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.8rem;
        color: var(--main-white);
        font-size: var(--font-xs);
        font-weight: var(--w-bold);
        transition: all 350ms ease-in-out;
      }
    }
  }
}

@media screen and (max-width: 835px) {
  .container {
    .content {
      min-width: 100vw;
      max-width: 100vw;
      margin: var(--spacing-small) auto;
    }
    .topContent {
      padding: 0 var(--spacing-small);
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.11);
      .sortContent{
        margin: var(--spacing-small) 0;
      }
    }
    .tabContent {
      padding: 0 var(--spacing-small);
      .tabs {
        max-width: 100%;
        overflow-x: auto;
        height: 3.2rem;
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        .tab {
          white-space: nowrap;
        }
      }
    }
  }
}
