@import "~styles";
.container {
  font-family: "Montserrat", sans-serif;
  @extend .opensans;
  padding: 10px 10px 10px 10px;
  background: var(--border-gray);
  background: rgba(214, 214, 214, 1);
  display: flex;
  flex-flow: column wrap;
  margin: 0px auto;
  margin-top: 10rem;
  &.noDistractionsMode {
    display: none;
  }
  .firstSection {
    display: flex;
    flex-flow: row wrap;
    > div {
      width: 50%;
    }
    .redes {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      a:nth-child(2) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  .secondSection {
    display: flex;
    height: 100%;
    flex-flow: column wrap;
    .column:last-child {
      div {
        margin-top: 0px;
      }
      margin-bottom: 2rem;
    }
    .column:nth-child(n + 2) {
      margin-top: 3rem !important;
      
    }
    .columnDimeMas {
        // height: 20rem;
        margin: 0px;
    }
    .column {
      .contactoTitle {
        display: none;
      }
      margin: 0 35px;
      #titlePros {
        color: var(--secondary-blue);
      }
      #titleUsers {
        color: var(--color-item);
      }
    }
    .list {
      h3 {
        color: gray;
        margin-top: 1.5rem;
        font-family: "Montserrat", sans-serif;
        font-weight: normal;
      }
      list-style: none;
      .footerLink {
        text-decoration: none;
      }
    }
  }
  .thirdSection {
    .bottomContent {
      margin: 0px auto;
      border-top: 1px solid gray;
      width: 85%;
      height: 30px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-weight: normal;
        margin-top: 1rem;
        color: gray;
        text-align: center;
      }
    }
  }
}
