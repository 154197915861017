.boxShadow {
  background: #ffffff;
  border: 1px solid rgba(11, 26, 50, 0.1);
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: calc(var(--spacing-small) * 2);
  width: 100%;
  position: relative;
  &.noShadow {
    border: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 835px) {
  .boxShadow {
    width: 100%;
    margin: auto;
    padding: var(--spacing-medium);
  }
}
