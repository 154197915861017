.registerSteps {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--spacing-xlarge);
  padding-bottom: 6.9rem;
  h1 {
    font-size: var(--font-xl-1);
    text-align: center;
    margin-bottom: var(--spacing-xlarge);
    max-width: 550px;
  }
}

@media screen and (max-width: 720px) {
  .registerSteps {
    h1 {
      margin-bottom: 0;
      font-size: var(--font-xl);
      padding: 0 var(--spacing-small);
    }
  }
}
