.generalSearchContent {
  .inputsContainer {
    display: flex;
    align-items: center;
    background-color: var(--main-white);
    width: 560px;
    height: 56px;
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 10rem;
  }
  .inputWrapper {
    position: relative;
    transition: all 0.5s;
    width: 100%;
    height: 100%;
    // border-radius: 8px 0px 0px 8px;
    border-radius: 4rem;
    border: 1px solid rgba(11, 26, 50, 0.1);
    #generalSearch {
      border-radius: 10rem;
      font-family: 'Open Sans'
    }
    #generalSearch::placeholder {
      color: #ff6e5d;
    }
    #generalSearch::-webkit-input-placeholder {
      color: #ff6e5d;
    }
    #generalSearch::-moz-placeholder {
      color: #ff6e5d;
    }
    #generalSearch:-ms-input-placeholder {
      color: #ff6e5d;
    }
    #generalSearch:-moz-placeholder {
      color: #ff6e5d;
    }
    input:focus {
      outline: none;
    }
    input ~ svg {
      fill: var(--main-black);
    }

    input::placeholder {
      color: #dedede;
      opacity: 1;
    }
    input {
      font-size: var(--font-m);
      height: 54px;
      border: unset;
      padding: 0 0 0 2rem !important;
      &.error {
        color: var(--color-error);
      }
      &.error ~ svg,
      &.error:focus ~ svg {
        fill: var(--color-error);
      }
    }
    .prosSearch ~ svg {
      left: 3.5%;
    }
    .prosSearch {
      width: 100%;
      padding-left: 4.5rem;
      margin-left: 0.5rem;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 5%;
      transform: translate(0%, -50%);
      fill: var(--main-blue-opacity);
      transition: all 0.5s;
    }
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  button {
    height: 54px;
  }
  .prosSearchResultWrapper,
  .zipCodeSearchResultWrapper {
    position: absolute;
    top: 110%;
    left: 0%;
    display: flex;
    flex-direction: column;
    background: var(--main-white);
    box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
      0px 4px 16px rgba(51, 51, 51, 0.08);
    border-radius: 8px;
    min-width: 300px;
    overflow: auto;
    max-height: 300px;
    z-index: 1;
    .prosCategory {
      display: flex;
      align-items: center;
      padding: 3px 17px;
      cursor: pointer;
      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      &:hover {
        background: #e9eced;
      }
    }
    h4 {
      font-size: var(--font-s);
      font-weight: var(--w-regular);
      color: var(--main-blue);
      text-align: left;
      white-space: nowrap;
      margin-right: 1.7rem;
      padding: 1.5rem 0;
    }
    .prosCategorySoon {
      display: block;
      background: rgba(255, 110, 93, 0.3);
      border-radius: 4px;
      color: #ff6e5d;
      font-weight: var(--w-bold);
      font-size: var(--font-xs);
      margin-left: auto;
      padding: 5px 8px;
    }
  }
  .sinBarrio {
    margin-top: 1.6rem;
    box-sizing: border-box;
    padding: 0.8rem;
    label {
      font-size: var(--font-m);
      color: var(--main-blue);
      margin-left: var(--spacing-xlarge);
      position: relative;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: -25%;
      height: 24px;
      width: 24px;
      background-color: var(--main-white);
      border-radius: 4px;
      border: 1px solid #d4d4d4;
      transition: background-color 350ms;
    }

    label:hover input ~ .checkmark {
      background-color: #ccc;
    }

    label input:checked ~ .checkmark {
      background-color: var(--secondary-blue);
      border: none;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    label input:checked ~ .checkmark:after {
      display: block;
    }

    label .checkmark:after {
      left: 9px;
      top: 3.5px;
      width: 7px;
      height: 14px;
      border: solid white;
      border-width: 0 2.5px 2.5px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      border-radius: 1.5px;
    }
  }
}

@media screen and (max-width: 835px) {
  .generalSearchContent {
    .inputsContainer {
      width: 75vw;
      height: 43px;
      input {
        all: unset;
        font-size: var(--font-s);
        height: 40px;
      }
      .prosSearch {
        width: 100%;
        padding-left: var(--spacing-large);
      }
      .prosSearch ~ svg,
      .locationSearch ~ svg {
        width: 16px;
      }
      .locationSearch {
        width: 100%;
        padding-left: 3rem;
        svg {
          width: 14px;
        }
      }
      button {
        // width: 27% !important;
        width: 27%;
        font-size: var(--font-s);
        height: 40px;
        padding: 0 1rem;
      }
    }
    .inputWrapper {
      position: relative;
      transition: all 0.5s;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      // border: 1px solid var(--main-black);
      border: none;
    }
    .prosSearchResultWrapper {
      min-width: 90vw;
    }
  }
}
