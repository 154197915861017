@import "~styles";
.container {
  max-height: 400px;
  overflow: auto;
}
.serviceCategoryName {
  font-size: var(--font-m);
  color: var(--secondary-gray);
  font-weight: var(--w-regular);
  margin-bottom: var(--spacing-small);
}
.dateContainer {
  min-height: max-content;
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-small);
}
.errorMessage{
  color: red;
}
.footer {
  padding-top: var(--spacing-medium);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.button {
  @extend .opensans, .pdv-10, .mainBackground, .base-font, .main-ease;
  width: 100%;
  max-width: 250px;
  max-height: 48px;
  color: var(--main-white);
  border-radius: var(--border-radius);
  background: var(--button-background);
  padding: 14px 24px;
  font-weight: var(--w-bold);
  font-size: var(--font-m);
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &.loading {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
@media screen and (max-width: 835px) {
  .container {
    max-height: 65vh;
  }
}
