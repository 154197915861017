.modalTitle {
  padding-bottom: var(--spacing-medium);
  margin-bottom: 3.2rem;
  border-bottom: 1px solid rgba(11, 26, 50, 0.1);
  .titles{
    display: flex;
    flex-flow: column wrap;
    .subTitle{
      display: flex;
      flex-flow: row wrap;
      .firstLogin{
        color: gray;
        margin-right: 1rem; 
        span{
          color: blue;
        }
      }
    }
  }
  
}

.modalTitleFirstLogin{
  display: flex;
  flex-flow: row wrap;
  .titles{
    margin-left: 1.5rem;
    width: 80%;
  }
  // .logo{
  //   margin-right: 2rem;
  // }
}


@media screen and (max-width: 835px) {
  .modalTitle {
    p {
      max-width: 90%;
    }
  }
}
