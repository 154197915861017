.w-100 {
  width: 100%;
}

.flex {
  display: flex;
}

.direction-row {
  flex-direction: row;
}

.direction-row-reverse {
  flex-direction: row-reverse;
}

.direction-column {
  flex-direction: column;
}

.direction-column-reverse {
  flex-direction: column-reverse;
}

.align-start {
  justify-content: flex-start;
}

.just-end {
  justify-content: flex-end;
}

.just-center {
  justify-content: center;
}

.just-around {
  justify-content: space-around;
}

.just-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-around {
  align-items: space-around;
}

.align-between {
  align-items: space-between;
}

.flex-center-items {
  display: flex;
  align-items: center;
  justify-content: center;
}
