.secondaryBackground {
  background-color: var(--secondary-background);
}

.mainBackground {
  background-color: var(--color-primary);
}

.grayBackground {
  background-color: var(--color-gray);
}

.blackBackground {
  background-color: var(--main-black);
}

.box-shadow {
  box-shadow: 3px 3px 13px 0px rgba(0, 0, 0, 0.25);
}

.invalid {
  border-color: var(--border-error);
  color: var(--border-error);
}

.valid {
  border-color: var(--border-valid);
}
