.input {
  -webkit-appearance: none;
  width: 100%;
  border: none;
  background-color: rgba(222, 222, 222, 0.2);
  padding: var(--spacing-small);
  font-size: var(--font-m);
  border-radius: 4px;
  transition: opacity 100ms, all 500ms ease-in-out;
  &::placeholder {
    opacity: 0.2;
    font-weight: var(--w-regular);
  }
  &:focus {
    border: unset;
    outline: none;
  }
  &:disabled {
    opacity: 0.4;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(249, 249, 249) inset !important;
    -webkit-text-fill-color: var(--main-black) !important;
    filter: none;  }
}
