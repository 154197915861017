.borderedItem {
  background: #ffffff;
  border: 1px solid rgba(11, 26, 50, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 6px;
  display: flex;
  align-items: center;
  margin-right: var(--spacing-small);
  margin-bottom: var(--spacing-small);
  // height: 3rem;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  &.subService{
    background: var(--terciary-gray);
    border: 1px solid var(--main-white);
    display: flex;
    justify-content: center;
    width: 100%;
    margin: var(--spacing-extrasmall);
  }
  &.subService:hover{
    background: var(--color-item-background);
    color: var(--color-item);
    border: 1px solid var(--color-item)
  }

  &.selectedSubService{
    background: var(--color-item);
    color: var(--main-white);
    display: flex;
    justify-content: center;
    width: 100%;
    margin: var(--spacing-extrasmall);
    border: 1px solid var(--color-item)
  }
  &.selectedSubService::before{
    content: "\2714";
    letter-spacing: 2px;
    padding-left: 5px;
    // margin-right: 5px;
  }

  &.switchContainer{
    display: flex;
    float: right;
    margin-right: 2rem;
    width: 15rem;
    align-items: center;
    border: 1px solid var(--color-item);
    border-radius: 25px;
  }
}
@media screen and (max-width: 835px) {
  .borderedItem {
    margin-bottom: 0.8rem;
    &.switchContainer{
      position: relative;
      display: inline;
    }
  }
}
