.container {
  display: flex;
  flex-direction: column;
  &.editMode {
    max-height: 60vh;
    overflow-x: hidden;
    .addService {
      font-size: var(--font-m);
      font-weight: var(--w-bold);
      color: var(--secondary-blue);
      text-align: right;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-height: 22.5px;
      span {
        font-size: var(--font-xl-1);
        font-weight: 400;
        display: inline-block;
        margin-right: 1rem;
      }
    }
    .serviceCategoryContainer {
      .subServiceList {
        .subService {
          margin: 0.8rem 0;
        }
      }
    }
  }
}

.inputcurrencyhide {
  position: relative;
}
.inputcurrencyhide::after {
  width: 90%;
  // max-width: 4rem;
  height: 30px;
  border-radius: 4px;
  padding: 0.2rem 1rem;
  background-color: rgb(249, 249, 249);
  color: rgba(11, 26, 50, 0.5);
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  content: "-";
}

// ····· SERVICE LIST STYLES ······ //
.modalServiceHeader{
  // border: 1px solid black;
  margin-top: var(--spacing-small);
  margin-left: var(--spacing-extrasmall);
  width: 100%;
  display: flex;
}
.serviceCategoryContainer {
  display: flex;
  flex-direction: column;
  .serviceCategoryHeader {
    display: flex;
    justify-content: space-between;
    // padding-bottom: var(--spacing-extrasmall);
    margin-top: var(--spacing-extrasmall);
    // border-bottom: 1px solid rgba(11, 26, 50, 0.1);
    .serviceCategoryName {
      font-size: var(--font-m);
      color: var(--main-white);
      font-weight: var(--w-medium);
      font-size: var(--font-xm);
    }
    .serviceCategoryOpenHandler {
      display: flex;
      // flex-flow: row wrap;
      // align-items: center;
      // justify-content: center;
      // width: fit-content;
      // border: 1px solid orange;
      cursor: pointer;
      p {
        font-size: var(--font-m);
        color: var(--secondary-blue);
      }
      .icon {
        cursor: pointer;
        width: 1.2rem;
        height: 1.2rem;
        fill: var(--secondary-blue);
        margin-left: 1rem;
        transition: all 350ms ease-in-out;
        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }
  .subServiceList {
    height: max-content;
    max-height: 0;
    transition: max-height 350ms ease-in-out;
    &.open {
      max-height: max-content;
    }
    .subService {
      display: flex;
      align-items: center;
      margin: var(--spacing-extrasmall) 0;
      margin-top: 0.5rem;
      .editValues {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
      }
    }
  }
}

@media screen and (max-width: 835px) {
  .container {
    &.editMode {
      max-height: 400px;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 0;
      }
      .addService {
        margin-top: 0;
      }
      .subServiceList {
        &.open {
          max-height: none;
        }
        .subService {
          justify-content: space-between;
          flex-wrap: wrap;
          margin: 0.8rem 0;
          > p {
            font-size: var(--font-s);
            width: 100%;
          }
          .editValues {
            margin-top: 0.8rem;
            width: 100%;
            justify-content: flex-start;
            svg {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
  .fromRegister {
    flex-wrap: wrap;
    margin: 0.8rem 0;
  }
  .serviceCategoryContainer {
    .serviceCategoryHeader {
      border-bottom: unset;
      padding-bottom: 0;
      // .serviceCategoryName{
        
      // }
    }
    .subServiceList {
      .subService {
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
