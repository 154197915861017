.alertMessage {
  display: block;
  background-color: rgb(222, 222, 222);
  padding: 1rem 0.8rem 1rem var(--spacing-xlarge);
  color: var(--main-blue);
  font-size: var(--font-xs);
  position: relative;
  border-radius: 4px;
  width: 100%;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
  opacity: 0.6;
  &:after {
    position: absolute;
    top: 50%;
    left: 2.5%;
    transform: translate(0%, -50%);
    display: block;
    content: "";
    background-position: center;
    background-size: 100%;
    fill: #323232;
    width: 18px;
    height: 18px;
  }
  &.alert::after {
    background: url("../../../assets/svg/alert.svg") no-repeat;
  }
}
