.container {
  display: grid;
  place-items: center;
  padding-top: calc(var(--spacing-medium) * 2);
}
.starReview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
