.container {
  position: relative;
  .icon {
    cursor: pointer;
    &:hover {
      ~ .tooltip {
        display: block;
        opacity: 1;
      }
    }
  }
  .tooltip {
    opacity: 0;
    display: none;
    position: absolute;
    top: 110%;
    z-index: 9;
    width: 300px;
    right: -50%;
    padding: 1.3rem;
    filter: drop-shadow(0px 4px 4px rgba(51, 51, 51, 0.04));
    background: var(--main-white);
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    transition: all 250ms ease-in-out;
    text-align: justify;
    &::before {
      position: absolute;
      top: -9%;
      left: 87%;
      content: "";
      width: 8px;
      height: 8px;
      background-color: var(--main-white);
      border-left: 1px solid #f1f1f1;
      border-top: 1px solid #f1f1f1;
      transform: rotate(45deg);
    }
  }
}

@media screen and (max-width: 835px) {
  .container {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
    margin-top: var(--spacing-medium);
    background: rgba(222, 222, 222, 0.6);
    border-radius: 4px;
    width: 100%;
    .icon {
      position: relative;
      width: 2rem;
      filter: grayscale(1);
      opacity: 0.5;
    }
    .tooltip {
      border: none;
      background: none;
      filter: none;
      opacity: 1;
      position: relative;
      top: 0;
      left: 2%;
      padding: 0;
      width: 100%;
      opacity: 0.6;
      &::before {
        display: none;
      }
    }
  }
}
