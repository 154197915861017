.filtersMenuMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: var(--main-white);
  z-index: 99;
  transform: translateX(100%);
  transition: transform 0.35s;
  overflow: auto;
  &.opened {
    transform: translateX(0%);
  }
  .filtersMenuMobileHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 2.4rem;
    p {
      font-size: var(--font-l);
      font-weight: var(--w-bold);
      color: var(--main-blue);
    }
  }
  label {
    font-size: var(--font-xs);
    color: var(--main-blue);
    position: relative;
    cursor: pointer;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    height: 2.4rem;
  }
  .checkbox {
    padding-left: var(--spacing-xlarge);
  }
  input {
    position: absolute;
    opacity: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0%;
    height: 1.9rem;
    width: 1.9rem;
    background-color: var(--main-white);
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    transition: background-color 350ms;
  }

  //   label:hover input ~ .checkmark {
  //     background-color: #ccc;
  //   }

  label input:checked ~ .checkmark {
    background-color: var(--secondary-blue);
    border: none;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  label input:checked ~ .checkmark:after {
    display: block;
  }

  label .checkmark:after {
    left: 7px;
    top: 3.5px;
    width: 6px;
    height: 11px;
    border: solid var(--main-white);
    border-width: 0 2.4px 2.4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-radius: 1.5px;
  }
  .filtersContainer {
    display: flex;
    flex-direction: column;
    padding: 0 2.4rem;
    .filterContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 1px solid rgba(222, 222, 222, 0.4);
      margin-bottom: 1.6rem;
      padding-bottom: 1.6rem;
      .filterTitle {
        font-weight: var(--w-bold);
      }
      p {
        font-size: var(--font-xs);
        color: var(--main-blue);
        width: 100%;
      }
      .filter {
        height: 4rem;
        width: 50%;
        display: flex;
        align-items: center;
        transition: all 250ms;
        > div {
          width: 100%;
          p {
            width: 80% !important;
          }
        }
        &.soon {
          > div {
            width: 100%;
            p {
              width: max-content !important;
              max-width: 47%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
        &.hidden {
          height: 0;
          opacity: 0;
        }
      }
      .viewMore {
        font-size: var(--font-s);
        color: var(--secondary-blue);
        display: block;
        width: 100%;
        margin-top: 1.6rem;
        text-align: center;
        z-index: 999;
      }
    }
  }
  .fixedBottom {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: var(--main-white);
    display: flex;
    align-items: center;
    padding: 0.8rem 2.75rem;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.06);
    .bottomButton {
      width: 50%;
      display: grid;
      place-items: center;
      p {
        font-size: var(--font-xs);
        font-weight: var(--w-bold);
        color: var(--main-blue);
        opacity: 0.5;
        text-decoration: underline;
      }
    }
  }
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.hidden {
    height: 0;
    opacity: 0;
  }
  .optionsHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.7rem;
    margin-top: 0.7rem;
    .optionName {
      font-size: var(--font-xs);
      font-weight: var(--w-regular);
    }
    .optionsOpenHandler {
      display: flex;
      align-items: center;
      cursor: pointer;
      p {
        font-size: var(--font-xs);
        color: var(--secondary-blue);
      }
      .icon {
        cursor: pointer;
        width: 1.2rem;
        height: 1.2rem;
        fill: var(--secondary-blue);
        margin-left: 1rem;
        transition: all 350ms ease-in-out;
        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }
  .barrios {
    display: flex;
    flex-wrap: wrap;
  }
}
