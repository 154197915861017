@import "~styles";
.optionCard {
  width: 44.4rem;
  height: 11.6rem;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  border: 1px solid rgba(11, 26, 50, 0.04);
  display: flex;
  transition: all 350ms;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  .icon {
    width: 21%;
    height: 100%;
    @extend .flex-center-items;
    .iconPentagon {
      clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
      // outline: 1px solid #dedede;
      // outline-offset: -1px;
      background-color: #dedede;
      border-radius: 4px;
      padding: 1.4rem;
      overflow: hidden;
      transition: all 350ms;
      @extend .flex-center-items;
      padding-bottom: 1rem;
      position: relative;
      svg {
        fill: var(--secondary-blue);
        transition: all 350ms;
        z-index: 2;
      }
      &::after {
        content: "";
        position: absolute;
        background: #fff;
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
        z-index: 1;
        transition: all 350ms;
      }
    }
  }
  .text {
    width: 66%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: var(--spacing-small);
    h6 {
      font-size: var(--font-l-1);
      margin-bottom: 0.5rem;
      font-weight: var(--w-semibold);
    }
    p {
      font-size: var(--font-s);
      opacity: 0.4;
    }
  }
  .arrow {
    width: 13%;
    @extend .flex-center-items;
    svg {
      opacity: 0;
      transition: all 350ms;
    }
  }
  &:hover,
  &:focus {
    background: rgba(222, 222, 222, 0.2);
    border-color: var(--secondary-blue);
    box-shadow: unset;
    .icon {
      .iconPentagon {
        svg {
          fill: var(--main-white);
        }
        &::after {
          background-color: var(--secondary-blue);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
    .arrow {
      svg {
        fill: var(--secondary-blue);
        opacity: 1;
      }
    }
  }
  &.selected {
    background: rgba(222, 222, 222, 0.2);
    border-color: var(--secondary-blue);
    box-shadow: unset;
    .icon {
      .iconPentagon {
        svg {
          fill: var(--main-white);
        }
        &::after {
          background-color: var(--secondary-blue);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
    .arrow {
      svg {
        fill: var(--secondary-blue);
        opacity: 1;
      }
    }
  }
  &.minimal {
    width: 26.4rem;
    justify-content: center;
    .icon,
    .text {
      width: auto;
      max-width: 15rem;
    }
  }
}

@media screen and (max-width: 835px) {
  .optionCard {
    width: 73%;
    height: 14rem;
    .icon {
      width: 30%;
    }
    .text {
      width: 70%;
      padding-right: 0.5rem;
    }
    .arrow {
      display: none;
    }
  }
}
