table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
}

th,
td {
  background-color: rgba(222, 222, 222, 0.2);
  padding: 0.5rem;
  padding: 0.5rem;
  padding-left: 1rem;
  height: 5rem;
  .done{
    background-color: #6aca4f;
    width: 2.2rem;
    border-radius: 50%;
    padding: 0.2rem;
  }
}

td:nth-last-child(-n+2){
  text-align: center;
  min-width: 8rem;
}

.avgReviews,
.reviews {
  text-align: center;
}

.thservicios,
.thmasSobreMi{
  min-width: 12rem;
  text-align: center;
}

.servicios,
.masSobreMi{
  text-align: center;
}

table {
    width: 100%;
}

thead tr th {
    text-align: left;
}

