@import "~styles";
.button {
  @extend .opensans, .pdv-10, .mainBackground, .base-font, .main-ease;
  width: 100%;
  max-width: 250px;
  max-height: 48px;
  color: var(--main-white);
  border-radius: var(--border-radius);
  background: var(--button-background);
  padding: 14px 24px;
  // font-weight: var(--w-bold);
  font-size: var(--font-m);
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    // background-color: var(--main-black);
    // color: var(--color-primary);
    opacity: 0.3;
    cursor: not-allowed;
  }
  &.right-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.right-arrow::after {
    margin-left: 1rem;
    content: "";
    display: inline-block;
    background: url("../../../assets/svg/right-arrow.svg") no-repeat;
    background-position: center;
    background-size: 100%;
    width: 25px;
    height: 25px;
  }
}
.secondary {
  background: var(--main-white);
  color: var(--main-black);
  font-weight: var(--w-regular);
}

.terciary {
  background: var(--main-white);
  color: var(--secondary-blue);
  border: 1px solid rgba(11, 26, 50, 0.2);
}
