.panelContainer {
  display: flex;
  flex-flow: row wrap;
  height: calc(100vh - 80px);
  .hamburguer{
    display: none;
  }
  .sectionFirst, .sectionFirstMobile {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    width: 23rem;
    background-color: rgba(222, 222, 222, 0.2);
    padding-top: 5rem;
    a {
      border-radius: 0.4rem;
      img:nth-child(n + 2) {
        margin-top: 1rem;
      }
    }
    > a:hover {
      background-color: #ccc;
    }
    ul {
      width: 50%;
      margin: 0.6rem 0rem 0.6rem 0rem;
      li {
        a {
          font-size: 1.5rem;
          color: black;
        }
        a:hover {
          color: rgba(129, 137, 149, 1);
        }
      }
      li:nth-child(n + 2) {
        margin-top: 1.5rem;
      }
    }
    .select {
      cursor: pointer;
      margin-top: 0.5rem;
      outline: none;
      border-radius: 0.4rem;
      max-width: 17rem;
      div {
        width: 14vw;
        font-size: 1.2rem;
      }
    }
  }
  .sectionFirstMobile{
    padding: 1rem 0rem 1.5rem 0rem;
  }
  .sectionTwo {
    display: flex;
    flex-flow: column wrap;
    width: calc(100vw - 25rem);
    max-width: calc(100vw - 24rem);
    height: calc(100%);
    .firstLine {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 95%;
      margin: 0px auto;
      margin-top: 3rem;
      span {
        width: fit-content;
        font-size: 2.5rem;
      }
      img {
        width: 15rem;
      }
    }
    .thirdLine {
      width: 95%;
      height: calc(100vh - 20rem);
      margin: 3rem 0rem 0rem 3rem;
      font-size: 1.5rem;
      overflow-y: scroll;
    }
  }
}

@media screen and (max-width: 835px) {
  .panelContainer {
    flex-flow: column wrap;
    height: 100%;
    position: relative;
    .sectionFirst{
      display: none
    }
    .hamburguer{
      display: block;
      margin: 2rem 0rem 0rem 2rem;
    }
    .hamburguerContainer{
      position: absolute;
      top: 8rem;
      left: 20px;
      background-color: white;
      border: 1px solid black;
      border-radius: 2rem;
    }
    .sectionTwo{
      width: 100%;
      max-width: 100%;
    }
  }
}
