@import "~styles";

.container {
  padding: var(--spacing-small);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background: linear-gradient(86.44deg, #2c0eb1 0.51%, #320fcc 121.26%);
  border: 1px solid rgba(11, 26, 50, 0.1);
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  .icon {
    width: 7.2rem;
    height: 4rem;
    transform: scale(2.3) translateY(0.7rem);
    margin: 0 0.8rem;
    margin-right: 1.6rem;
  }
}
.titleSubtitle {
  margin: 0 var(--spacing-medium);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--main-white);
  @extend .montserrat;
}
.title {
  font-size: var(--font-xl-1);
  color: var(--main-white);
  @extend .montserrat;
}
.subtitle {
  font-size: var(--font-m);
  line-height: 24px;
  margin-top: 0.8rem;
  font-weight: var(--w-regular);
  color: var(--main-white);
  @extend .montserrat;
  padding-left: 10rem;
}

@media screen and (max-width: 835px) {
  .title {
    font-size: var(--font-l-1);
    width: 75%;
  }
  .subtitle {
    padding-left: 0;
  }
  .container {
    .icon {
      width: 4.4rem;
      transform: scale(1.7) translateY(0rem);
    }
  }
}
