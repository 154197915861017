.container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-small);
  max-width: 65%;
  margin-right: auto;
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    .name {
      font-size: var(--font-m);
      font-weight: var(--w-bold);
      color: var(--secondary-blue);
    }
    .time {
      font-size: var(--font-s);
      color: var(--main-blue);
      opacity: 0.4;
      margin-left: var(--spacing-small);
    }
  }
  .body {
    font-size: var(--font-m);
  }
}
