.container {
  width: 100vw;
  max-width: 52.8rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
  .shareButtons {
    display: flex;
    flex-flow: row nowrap;
    div {
      margin-left: 2rem !important;
      width: 5rem !important;
      height: 5rem !important;
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: space-evenly;
      // border: 1px solid blue;
      .iconCopy{
        width: 3rem;
        height: 3rem;
        cursor: pointer;
      }
      button{
        svg{
          width: 4rem;
        }
      }
      width: fit-content;
      height: fit-content;
    }
    div:nth-child(4){
      position: relative;
      border: 1px solid orange;
      border-radius: 50%;
      width: 3.8rem !important;
      height: 3.8rem !important;
      margin-top: 0.5rem;
      background-color: orange;
      color: white;
      .copySpan{
        position: absolute;
        top: 4.3rem;
        left: -8px;
        color: black;
        width: fit-content !important;
        background-color: #ccc;
        border-radius: 2rem;
        padding: 0.3rem 0.5rem 0.3rem 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 835px) {
  .container {
    max-width: 80vw;
  }
}
