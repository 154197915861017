@import "~styles";

.containerTestimonials {
  // height: 370px;
  // margin-top: 8rem;
  // height: fit-content !important;
  .title {
    margin: 0px auto;
    margin-top: var(--spacing-medium);
    margin-bottom: var(--spacing-medium);
    // margin-left: 6.2rem;
    font-family: "Montserrat";
    font-weight: var(--w-small);
    color: var(--secondary-blue);
    @include respond(tab-port) {
      margin-bottom: 2rem;
      margin-left: 3.4rem;
      font-size: 2.2rem !important;
    }
    ul {
      margin-bottom: 3rem !important;
    }
  }
  // .fondo {
  //   width: 100%;
  //   .carouselContainer {
  //     div {
  //       div {
  //         ul {
  //           display: none;
  //           li {
  //             div {
  //               width: 100%;
  //               img {
  //                 width: 100%;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  .sonsCarousel {
    img {
      width: 100%;
      margin: 0px auto;
      // aspect-ratio: 16 / 6;
    }
  }
}

// ============ CELULAR ===========

@media screen and (max-width: 835px) {
  .containerTestimonials {
    height: fit-content !important;
    // margin-top: 1rem;
    // border: 3px solid black;
    .sonsCarousel {
      img {
        width: 100%;
        margin: 0px auto;
      }
    }
    // .fondo {
    //   div {
    //     div {
    //       div {
    //         // border: 3px solid palevioletred;
    //         height: 50%;
    //         ul {
    //           display: none;
    //           li {
    //             // width: 50%;
    //             div {
    //               // width: 100%;
                  
    //               img {
    //                 // border: 1px solid red;
    //                 // height: fit-content !important;
    //                 width: 100vw;
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

@media screen and (min-width: 1000px) {
  .containerTestimonials {
    height: 300px;
  }
}

@media screen and (min-width: 1300px) {
  .containerTestimonials {
    height: 500px;
  }
}

@media screen and (min-width: 1600px) {
  .containerTestimonials {
    height: 670px;
  }
}

@media screen and (min-width: 1900px) {
  .containerTestimonials {
    height: 770px;
  }
}

@media screen and (min-width: 2200px) {
  .containerTestimonials {
    height: 870px;
  }
}
