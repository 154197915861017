.questionsBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-small);
  background: linear-gradient(
    271.68deg,
    rgba(197, 197, 197, 0.2) 1.64%,
    rgba(222, 222, 222, 0.2) 99.01%
  );
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 8px;
}
