.container {
  display: flex;
  align-items: center;
  background-color: var(--main-white);
  padding: calc(var(--spacing-small) / 2) var(--spacing-small);
  border-radius: 100px;
  margin-right: calc(var(--spacing-small) / 2);
  margin-bottom: calc(var(--spacing-small) / 2);
  > p {
    max-width: 450px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .delete {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    margin-left: calc(var(--spacing-small) / 2);
    .icon {
      width: 100%;
      height: 100%;
    }
  }
}
