.container {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-medium);
  margin-bottom: calc(var(--spacing-small) * 2);
  border-bottom: 1px solid rgba(11, 26, 50, 0.1);
  &:last-of-type {
    margin-bottom: var(--spacing-small);
  }
  .row,
  .nameContactType {
    display: flex;
    align-items: center;
  }
  .alias {
    color: gray;
    font-style: normal;
    margin-bottom: 2.5rem;
  }
  .title{
    margin-top: 2rem;
    font-size: 1rem;
    color: black;
  }
  .topRow {
    display: flex;
    align-items: center;
  }
  .name {
    font-size: var(--font-l);
    margin-right: var(--spacing-small);
  }
  .status {
    margin-left: auto;
  }
  .status,
  .contactType {
    font-size: var(--font-xs);
    font-weight: var(--w-bold);
    display: block;
    padding: 0.4rem 0.8rem;
    border: 1px solid #b5b5b5;
    color: #b5b5b5;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #fff;
    text-transform: uppercase;
    &.contacto-telefonico {
      background-color: rgba(106, 0, 204, 0.08);
      border-color: #6a00cc;
      color: #6a00cc;
    }
    &.contacto-por-mensaje {
      background-color: rgba(121, 250, 135, 0.14);
      border-color: #02680d;
      color: #02680d;
    }
    &.approved {
      border-color: #02680d;
      color: #02680d;
    }
    &.pending {
      border-color: #723f09;
      color: #723f09;
    }
    &.rejected {
      border-color: #ff4949;
      color: #ff4949;
    }
  }
  .dataText {
    font-size: var(--font-s);
    margin: 0.6rem 0.8rem;
  }
  .servicesList {
    margin-top: var(--spacing-small);
    p {
      font-size: var(--font-s);
      margin-bottom: 0.8rem;
    }
    .service {
      font-size: var(--font-m);
      margin-bottom: 0;
    }
  }
  .messages {
    margin-top: var(--spacing-small);
    p {
      font-size: var(--font-s);
      margin-bottom: 0.8rem;
    }
    .messageContent {
      padding: var(--spacing-medium);
      background-color: #fafafa;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      border-radius: 6px;
      p {
        font-size: var(--font-m);
        margin-bottom: 0;
      }
    }
  }
  .qualification {
    margin-bottom: 0.5rem;
    .qualificationValue {
      display: flex;
      align-items: center;
      svg {
        margin: 0 0.4rem;
      }
      p {
        font-size: var(--font-xm);
        margin-bottom: 0;
        &.value {
          color: #f4ac39;
          font-weight: var(--w-bold);
        }
      }
    }
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .buttons {
    margin-top: var(--spacing-medium);
  }
  .openController {
    width: 100%;
    border-top: 1px solid rgba(11, 26, 50, 0.1);
    margin-top: var(--spacing-large);
    padding-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: var(--secondary-blue);
      font-size: var(--font-m);
      font-weight: var(--w-regular);
      cursor: pointer;
    }
    .icon {
      fill: var(--secondary-blue);
      width: 1.6rem;
      margin-left: 0.8rem;
      transition: all 350ms ease-in-out;
      &.opened {
        transform: rotate(180deg);
      }
    }
  }
  .bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 50%;
      &.qualificationTab {
        width: 100%;
      }
    }
  }
  .worksLength {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background-color: rgba(11, 26, 50, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-white);
    font-size: var(--font-xs);
    font-weight: var(--w-bold);
    transition: all 350ms ease-in-out;
    margin-left: var(--spacing-small);
  }
}

@media screen and (max-width: 835px) {
  .container {
    .row {
      flex-wrap: wrap;
    }
    .servicesList {
      margin-bottom: 0.8rem;
    }
    .coinValue {
      font-size: var(--font-m);
    }
    .topRow {
      align-items: flex-start;
      .status {
        width: max-content;
      }
    }
    .nameContactType {
      flex-direction: column;
      align-items: flex-start;
      .contactType {
        margin-top: var(--spacing-small);
      }
    }
    .bottom {
      .buttons {
        flex-direction: column;
        width: 100%;
      }
    }
  }
  .phoneCall {
    width: 100%;
    display: flex;
    height: 4.8rem;
    align-items: center;
    justify-content: center;
    color: var(--secondary-blue);
    border: 1px solid rgba(11, 26, 50, 0.2);
    border-radius: 6px;
    font-size: var(--font-m);
    font-weight: var(--w-bold);
    margin: var(--spacing-small) 0;
  }
}

@media screen and (max-width: 360px) {
  .container {
    .row {
      margin-left: 0 !important;
    }
  }
}
