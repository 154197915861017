@import "./typography.scss";
@import "./spacing.scss";
@import "./media.scss";
@import "./colors.scss";
@import "./motion.scss";
@import "./utils.scss";

h3, h4{
    text-decoration: none !important;
}
