.profileInfoCard {
  display: grid;
  grid-template-rows: repeat(6, 3.05rem);
  grid-template-columns: 28% 32rem auto;
  // min-height: 18.3rem;
  position: relative;
  .nameRating {
    display: flex;
    grid-column: 2 / span 2;
    grid-row: 1 / span 1;
    .alias {
      color: black;
      font-style: normal;
    }
  }
  .name{
   color: gray;
  }  
  .editIcon {
    position: absolute;
    top: 0%;
    right: 0%;
    cursor: pointer;
    opacity: 0.5;
    transition: all 350ms ease-in-out;
    &:hover {
      opacity: 1;
      fill: var(--secondary-blue);
    }
  }
  .rating {
    align-self: center;
    font-size: 1rem;
    font-weight: var(--w-bold);
    display: block;
    padding: 0.4rem 0.8rem;
    border: 1px solid rgba(11, 26, 50, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
    margin-left: var(--spacing-small);
    &.leyenda {
      background-color: rgba(121, 250, 135, 0.14);
      border-color: #02680d;
      color: #02680d;
    }
    &.nuevo {
      background-color: rgba(247, 181, 76, 0.2);
      border-color: #845100;
      color: #845100;
    }
    &.top-seller {
      background-color: rgba(255, 110, 93, 0.14);
      border-color: #700e02;
      color: #700e02;
    }
    &.profesional {
      background-color: rgba(155, 81, 224, 0.1);
      border-color: #6a00cc;
      color: #6a00cc;
    }
  }
  .image {
    grid-column: span 1;
    grid-row: span 6;
    width: 18.3rem;
    height: 18.3rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
    .noImage {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 2px dashed rgba(11, 26, 50, 0.2);
      background: rgba(222, 222, 222, 0.5);
      .icon {
        width: 50%;
        height: 50%;
      }
    }
  }
  .location {
    grid-row: 1 / span 1;
    grid-column: 3 / span 1;
  }
  .workModality {
    grid-row: 2 / span 2;
    grid-column: 3 / span 1;
  }

  .workModality {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .proProfession {
    font-size: var(--font-l);
    opacity: 0.4;
    font-weight: var(--w-regular);
    align-self: end;
    white-space: nowrap;
    line-height: 0rem;
  }
  .proPrice {
    grid-row: 4 / span 1;
    grid-column: 2 / span 1;
    color: var(--secondary-blue);
    font-size: var(--font-xm);
    font-weight: var(--w-bold);
    display: flex;
    align-items: center;
    margin-top: 3rem;
    &.averagePrice::after {
      content: "Valor de referencia";
      color: var(--main-blue);
      font-size: var(--font-s);
      font-weight: var(--w-regular);
      opacity: 0.4;
      margin-left: 0.8rem;
    }
  }
  .tel {
    grid-row: 5 / span 1;
    grid-column: 2 / span 1;
    font-size: var(--font-m);
    font-weight: var(--w-regular);
    align-self: end;
  }
  .email {
    grid-row: 6 / span 1;
    grid-column: 2 / span 1;
    font-size: var(--font-m);
    font-weight: var(--w-regular);
  }
  .proReputationContainer {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    h6 {
      font-size: var(--font-m);
      font-weight: var(--w-regular);
      b {
        color: var(--main-blue);
        font-weight: var(--w-regular);
        margin-left: 0.5rem;
        opacity: 0.4;
      }
    }
    .proReputationNumber {
      color: #f4ac39;
      padding-left: 0.25rem;
      display: flex;
      align-items: center;
      font-weight: var(--w-bold);
      &::before {
        content: "";
        display: block;
        background: url("../../../assets/svg/star.svg") no-repeat;
        background-position: center;
        background-size: 100%;
        height: 1.6rem;
        width: 1.6rem;
        margin-right: 0.25rem;
      }
    }
  }
  .iconContainer {
    grid-row: 1 / span 1;
    grid-column: 3 / span 1;
    height: 100%;
  }
  &.publicMode {
    grid-template-rows: repeat(8, 3.05rem);
    grid-template-columns: 24% 32rem auto;
    .shareProfile{
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      width: fit-content;
      height: fit-content;
      margin: 0px auto;
      border: 1px solid rgba(11, 26, 50, 0.2);
      border-radius: 20%;
      // padding: var(--spacing-small);
      padding: 0.8rem;
      svg{
        color: rgb(30, 136, 229);
        font-size: var(--font-l);
        font-weight: var(--w-regular);
      }
      a{
        color: rgb(30, 136, 229);
        font-size: var(--font-m);
        font-weight: var(--w-regular);
      }
    }
    .publicButtons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      grid-row: 7 / span 2;
      grid-column: 1 / span 3;
    }
    .image {
      width: 15rem;
      height: 15rem;
    }
    .workModality {
      width: 204px;
      margin-left: auto;
    }
    .iconContainer {
      grid-row: 1 / span 1;
      grid-column: 3 / span 1;
      margin-left: 4.5rem;
    }
  }
  &.isProLogged {
    grid-template-rows: repeat(5, 3.05rem);
  }
  &.userMode {
    grid-template-rows: repeat(4, 3.4rem);
    // min-height: 13.4rem;
    grid-template-columns: 21% 32rem auto;
    .tel {
      grid-row: 4 / span 1;
    }
    .email {
      grid-row: 3 / span 1;
    }
    .image {
      grid-row: span 4;
      width: 13.4rem;
      height: 13.4rem;
    }
  }
}

@media screen and (max-width: 835px) {
  .profileInfoCard {
    grid-template-rows: repeat(8, 3.25rem);
    grid-template-rows: auto auto 3rem auto repeat(4, 3rem);
    grid-template-columns: 25% auto;
    grid-column-gap: var(--spacing-small);
    .iconContainer {
      grid-row: 3 / span 1;
      grid-column: 2 / span 1;
      display: flex;
    }
    .workModality {
      grid-row: 6 / span 1;
      grid-column: 1 / span 2;
      width: 83.2vw !important;
      margin: 0px auto;
      justify-content: flex-start;
    }
    .tel {
      grid-row: 7 / span 1;
      grid-column: 1 / span 2;
    }
    .email {
      grid-row: 8 / span 1;
      grid-column: 1 / span 2;
      margin-top: 1rem;
    }
    .image {
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;
      width: clamp(7.6rem, 100%, 12rem);
      height: clamp(7.6rem, 100%, 12rem);
    }
    .proReputationContainer {
      grid-row: 4 / span 1;
      grid-column: 1 / span 2;
      margin-top: 1rem;
      h6 {
        font-size: var(--font-l);
      }
    }
    .proProfession {
      grid-row: 2 / span 1;
      grid-column: 2 / span 1;
      height: 100%;
      white-space: normal;
      line-height: 4rem;
    }
    .proPrice {
      grid-row: 5 / span 1;
      grid-column: 1 / span 2;
      margin-top: 0;
    }
    .nameRating {
      grid-row: 1 / span 1;
      grid-column: 2 / span 1;
    }
    &.publicMode {
      grid-template-rows: 3.25rem auto repeat(8, 3.25rem);
      grid-template-columns: 7.6rem auto;
      .publicButtons {
        margin-top: 0.8rem;
        flex-direction: column-reverse;
        grid-row: 9 / span 2;
        grid-column: 1 / span 2;
        & button:first-child {
          margin-top: var(--spacing-small);
        }
      }
      .image {
        width: clamp(7.6rem, 100%, 12rem);
        height: clamp(7.6rem, 100%, 12rem);
      }
      .workModality {
        grid-column: 1 / span 1;
      }
      .iconContainer {
        grid-row: 3 / span 1;
        grid-column: 2 / span 1;
        margin-left: 0rem;
      }
    }
    &.isProLogged {
      grid-template-rows: auto auto 3rem auto repeat(4, 3rem);
      grid-template-columns: 25% auto;
    }
    &.userMode {
      grid-template-rows: repeat(4, 25%);
      min-height: 13.4rem;
      grid-template-columns: 26% 32rem auto;
      .proReputationContainer,
      .tel,
      .email {
        grid-column: 2 / span 1;
      }
      .proReputationContainer {
        grid-row: 2 / span 1;
        margin-top: 0;
        margin-bottom: 0;
      }
      .email {
        margin-top: 0.8rem;
      }
      .image {
        grid-row: span 4;
        width: 7.6rem;
        height: 7.6rem;
        img {
          border-radius: 10px;
        }
      }
    }
  }
}
