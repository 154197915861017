.container {
  position: relative;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.06);
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.06));
  border-radius: 6px;
  padding: 0.3rem 1.6rem;
  min-width: max-content;
  .name {
    font-size: var(--font-m);
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    &::after {
      content: "";
      display: inline-block;
      background: url("../../../assets/svg/down-chevron.svg") no-repeat;
      background-size: 100%;
      background-position: center;
      width: 12px;
      height: 12px;
      transition: all 500ms;
      margin-left: auto;
    }
    &.opened::after {
      transform: translate(0%, 0%) rotate(180deg);
    }
  }
  .list {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    .option {
      cursor: pointer;
      background-color: var(--main-white);
      font-size: var(--font-m);
      padding: 0.3rem 1.6rem;
      &:hover {
        background-color: #dedede;
      }
    }
  }
}
  .container:hover{
    z-index: 20;
  }
