.container {
  display: flex;
  align-items: center;
  font-size: var(--font-m);
  svg {
    fill: var(--secondary-blue);
  }
  .text {
    margin-left: 0.3rem;
  } 
}
