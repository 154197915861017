.container {
  // width: 100vw;
  max-width: 46rem;
  height: fit-content;
  max-height: 85vh;
  box-sizing: border-box;
  margin-bottom: 2rem;
  .infoCenter {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    h2 {
      font-weight: normal;
      font-size: 2rem;
      text-align: center;
      margin-top: 3rem;
    }
    .imageDel {
      img {
        margin: 0px auto;
      }
    }
    .buttons {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      justify-content: space-evenly;
      margin-top: 3rem;
      img{
        cursor: pointer;
      }
    }
  }
}
