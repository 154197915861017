@import "~styles";

.containerProcess {
  margin-top: 5rem;
  .title {
    margin: 0px auto;
    margin-bottom: var(--spacing-medium);
    // margin-left: 6.2rem;
    font-family: "Montserrat";
    font-weight: var(--w-small);
    color: var(--secondary-blue);
    @include respond(tab-port) {
      margin-bottom: 2rem;
      margin-left: 3.4rem;
      font-size: 2.2rem !important;
    }
  }
  .sonsCarousel {
    img {
      width: 60%;
      margin: 0px auto;
    }
  }
  // .fondo {
  //   width: 100%;
  //   .carousel {
  //     div {
  //       // div {
  //       //   border: 3px solid blue;
  //       // }
  //       ul {
  //         display: none;
  //         li {
  //           // width: 50%;
  //           // height: fit-content;
  //           div {
  //             width: 100%;
  //             img {
  //               // border: 1px solid red;
  //               width: 60%;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}


@media screen and (max-width: 835px) {
  .containerProcess {
    margin-top: 1rem;
    margin-bottom: 3rem;
    .sonsCarousel {
      img {
        width: 90%;
        margin: 0px auto;
      }
    }
    // .fondo {
    //   .carousel {
    //     div {
    //       ul {
    //         display: none;
    //         li {
    //           // width: 50%;
    //           div {
    //             width: 100%;
    //             img {
    //               // border: 1px solid red;
    //               width: 100%;
    //             }
    //           }
    //         }
    //         // li:nth-child(2) {
    //         //   // width: 50%;
    //         //   div {
    //         //     width: 100%;
    //         //     img {
    //         //       // border: 1px solid red;
    //         //       width: 65%;
    //         //     }
    //         //   }
    //         // }
    //         // li:nth-child(n + 6) {
    //         //   // width: 50%;
    //         //   div {
    //         //     width: 100%;
    //         //     img {
    //         //       // border: 1px solid red;
    //         //       width: 45%;
    //         //     }
    //         //   }
    //         // }
    //       }
    //     }
    //   }
    // }
  }
}

@media screen and (min-width: 1000px) {
  .containerProcess {
    height: 410px;
  }
}

@media screen and (min-width: 1300px) {
  .containerProcess {
    height: 510px;
  }
}

@media screen and (min-width: 1600px) {
  .containerProcess {
    height: 610px;
  }
}

@media screen and (min-width: 1900px) {
  .containerProcess {
    height: 710px;
  }
}

@media screen and (min-width: 2200px) {
  .containerProcess {
    height: 810px;
  }
}
