.filtersContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4.8rem;
}

.mobileButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 47.5rem;
  margin-bottom: 1.6rem;
}
.mobileButton {
  width: 100%;
  height: var(--spacing-xlarge);
  border: 1px solid rgba(11, 26, 50, 0.2);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: var(--secondary-blue);
    font-size: var(--font-s);
    font-weight: var(--w-bold);
    margin-left: 0.6rem;
  }
}

@media screen and (max-width: 835px) {
  .filtersContainer {
    flex-direction: column;
    margin-bottom: var(--spacing-medium);
    padding: 0 var(--spacing-medium);
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.11);
  }
}
