@import "~styles";

.proCardContainer {
  width: 100%;
  display: flex;
  background: #ffffff;
  border: 2px solid rgba(11, 26, 50, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: var(--spacing-medium);
  margin-bottom: var(--spacing-medium);
  cursor: pointer;
  .proCardImage {
    margin-right: var(--spacing-medium);
    height: 22rem;
    width: 27.7rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  .noImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22rem;
    height: 22rem;
    border-radius: 10px;
    border: 2px dashed rgba(11, 26, 50, 0.2);
    background: rgba(222, 222, 222, 0.5);
    .icon {
      width: 50%;
      height: 50%;
    }
  }
  .proCardInfo {
    width: 100%;
    display: grid;
    grid-template-columns: 49% 50%;
    grid-template-rows: auto 3rem 3rem auto auto auto;
    grid-column-gap: 1rem;
    .nameRating {
      display: flex;
      align-items: center;
      .alias {
        color: black;
        font-style: normal;
      }
      h4 {
        margin-bottom: 0;
        margin-right: var(--spacing-medium);
      }
      .rating {
        font-size: 1rem;
        font-weight: var(--w-bold);
        display: block;
        padding: 0.4rem 0.8rem;
        border: 1px solid rgba(11, 26, 50, 0.1);
        box-sizing: border-box;
        border-radius: 6px;
        text-transform: uppercase;
        height: max-content;
        &.leyenda {
          background-color: rgba(121, 250, 135, 0.14);
          border-color: #02680d;
          color: #02680d;
        }
        &.nuevo {
          background-color: rgba(247, 181, 76, 0.2);
          border-color: #845100;
          color: #845100;
        }
        &.top-seller {
          background-color: rgba(255, 110, 93, 0.14);
          border-color: #700e02;
          color: #700e02;
        }
        &.profesional {
          background-color: rgba(155, 81, 224, 0.1);
          border-color: #6a00cc;
          color: #6a00cc;
        }
      }
    }
    .location {
      margin-left: auto;
    }
    .workModality {
      margin-left: auto;
      display: flex;
      align-items: center;
      grid-column: 2 / span 1;
      grid-row: 2 / span 1;
    }
    .proProfession {
      grid-column: 1 / span 1;
      grid-row: 4 / span 1;
      font-size: var(--font-l);
      opacity: 0.4;
      font-weight: var(--w-bold);
    }
    .proPrice {
      grid-column: 1 / span 1;
      grid-row: 5 / span 1;
      color: var(--secondary-blue);
      font-size: var(--font-xm);
      font-weight: var(--w-bold);
      display: flex;
      align-items: center;
      margin: 0.5rem 0 1rem;
      &.averagePrice::after {
        content: "Valor de referencia";
        color: var(--main-blue);
        font-size: var(--font-s);
        font-weight: var(--w-regular);
        opacity: 0.4;
        margin-left: 0.8rem;
      }
    }
    .proCardBottomLeft {
      grid-column: 1 / span 1;
      grid-row: 6 / span 1;
      width: 100%;
      margin-right: var(--spacing-small);
      .proDescription {
        font-size: var(--font-m);
        line-height: 24px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .proCardBottomRight {
      grid-column: 2 / span 1;
      grid-row: 6 / span 1;
      width: 100%;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      align-items: center;
      button {
        padding: 0 0.8rem;
        min-height: 48px;
        &:last-child {
          max-width: max-content;
        }
      }
    }
  }
  .qualification {
    grid-row: 2;
    p {
      font-size: var(--font-s);
      margin-bottom: 0.8rem;
    }
    .qualificationValue {
      display: flex;
      align-items: center;
      svg {
        margin: 0 0.4rem;
      }
      p {
        font-size: var(--font-xm);
        margin-bottom: 0;
        &.value {
          color: #f4ac39;
          font-weight: var(--w-bold);
          b {
            color: var(--main-blue);
            opacity: 0.4;
            margin-left: 0.5rem;
            font-weight: var(--w-regular);
          }
        }
      }
    }
  }
  &.isProLogged {
    .proCardInfo {
      grid-template-rows: auto 3rem auto auto auto auto;
    }
  }
}

@media screen and (max-width: 835px) {
  .proCardContainer {
    padding: var(--spacing-small);
    // padding-bottom: 11rem;
    position: relative;
    min-height: 34rem;
    margin-bottom: var(--spacing-small);
    .proCardImage {
      margin-right: var(--spacing-small);
      width: 8rem;
      height: 8rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .noImage {
      width: 8rem;
      height: 8rem;
    }
    .qualification {
      margin-bottom: 0;
    }
    .proCardInfo {
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto auto auto auto auto 12rem;
      .nameRating {
        flex-wrap: wrap;
        h4 {
          margin-bottom: 0;
          margin-right: 0;
          border-top: unset;
          padding: 0;
          font-size: clamp(2vw, 3.8vw, var(--font-m));
        }
        .rating {
          margin-left: auto;
          font-size: clamp(2vw, 2.9vw, 1rem);
        }
      }
      .location {
        grid-column: 1 / span 1;
        grid-row: 4 / span 1;
        margin-left: 0;
      }
      .qualification {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
      }
      .proProfession {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
        font-size: var(--font-m);
      }
      .workModality {
        grid-column: 1 / span 1;
        grid-row: 6 / span 1;
        margin-left: 0;
      }
      .proPrice {
        grid-column: 1 / span 1;
        grid-row: 5 / span 1;
        margin: 0;
        &.averagePrice::after {
          font-size: clamp(2vw, 3.3vw, var(--font-xs));
        }
      }
      .proCardBottomLeft {
        width: 100%;
        margin-right: 0;
        display: none;
        .proReputationContainer {
          margin-bottom: 0.5rem;
          h6 {
            font-size: clamp(2vw, 3.8vw, var(--font-m));
            font-weight: var(--w-regular);
            b {
              font-weight: var(--w-regular);
            }
          }
        }
      }
      .proCardBottomRight {
        width: 100%;
        height: auto;
        grid-column: 1 / span 1;
        grid-row: 7 / span 2;
        flex-direction: column;
        transform: translate(-50%);
        position: absolute;
        bottom: 16px;
        left: 50%;
        button {
          font-size: var(--font-s);
          padding: 1vw 1.325rem;
          height: 54px;
          width: 78vw;
          max-width: unset;
          &:last-child {
            max-width: unset;
          }
        }
      }
    }
    &.isProLogged {
      min-height: 20rem;
      .proCardInfo {
        grid-template-rows: auto auto auto auto auto auto;
      }
    }
  }
}
