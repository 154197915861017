@import "~styles";

.containerKpi {
  height: fit-content !important;
	background-color: rgba(246, 246, 246, 1);
	margin-bottom: 6rem;
	.title {
		margin: 0px auto;
		margin-bottom: var(--spacing-medium);
		font-family: "Montserrat";
		font-weight: var(--w-small);
		color: var(--secondary-blue);
		@include respond(tab-port) {
			margin-bottom: 2rem;
			margin-left: 3.4rem;
			font-size: 2.2rem !important;
		}
	}
	ul {
		margin-bottom: 3rem !important;
	}
	.sonsCarousel {
		img {
			width: 60%;
			margin: 0px auto;
		}
	}
}

@media screen and (max-width: 835px) {
	.containerKpi {
		margin: 3rem 0rem 2rem 0rem;
		padding: 2rem 2rem;
		ul {
			margin-bottom: 13px !important;
		}
		.sonsCarousel {
			img {
				width: 100%;
				margin: 0px auto;
			}
		}
	}
}

@media screen and (min-width: 835px) {
	.containerKpi {
		height: 200px;
	}
}

@media screen and (min-width: 1000px) {
	.containerKpi {
		height: 470px;
	}
}

@media screen and (min-width: 1300px) {
	.containerKpi {
		height: 570px;
	}
}

@media screen and (min-width: 1600px) {
	.containerKpi {
		height: 670px;
	}
}

@media screen and (min-width: 1900px) {
	.containerKpi {
		height: 770px;
	}
}

@media screen and (min-width: 2200px) {
	.containerKpi {
		height: 870px;
	}
}
