.containerDimeMas {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    border-radius: 4rem;
    background-color: #fff;
    width: 100%;
    padding: 1rem;
    margin-top: 0px;
    .sectionDimeMas {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        span {
            font-size: 1.5rem;
        }
        span:nth-child(2) {
            margin: 0.5rem 0 0.5rem 0;
        }
    }
}

@media screen and (max-width: 1423px) {
    .containerDimeMas {
        .sectionDimeMas {
            margin-top: 1.5rem;
        }
    }
}

@media screen and (max-width: 835px) {
    .containerDimeMas {
      width: 100%;
      margin: 0px auto;
      margin-top: 2.5rem;
      .sectionDimeMas {
          .span {
            font-size: 1rem;
        }
      }
    }
}