.container {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-medium);
  background: linear-gradient(
    271.68deg,
    rgba(197, 197, 197, 0.2) 1.64%,
    rgba(222, 222, 222, 0.2) 99.01%
  );
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 80rem;
  .header {
    width: 100%;
    margin-bottom: 4rem;
  }
  .body {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .icon {
      width: 15rem;
      margin-right: 1rem;
    }
  }
}

@media screen and (max-width: 835px) {
  .container {
    max-width: 100%;
    padding: var(--spacing-small);
    .header {
      margin-bottom: 2rem;
    }
    .body {
      margin-bottom: 0;
      flex-wrap: wrap;
      .icon {
        width: 35%;
        margin-right: 0;
      }
      p {
        width: 65%;
      }
      button {
        margin-top: var(--spacing-small);
      }
    }
  }
}
