.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
  }
  .title {
    margin: calc(var(--spacing-medium) * 2) 0;
  }
  .bottom {
    display: flex;
    align-items: center;
    margin: calc(var(--spacing-medium) * 2) 0;
  }
}

@media screen and (max-width: 835px) {
  .container {
    padding: 0 1rem;
    .title {
      margin: calc(var(--spacing-small) * 2) 0;
    }
    .bottom {
      margin: calc(var(--spacing-small) * 2) 0;
      flex-direction: column;
      button {
        margin-top: var(--spacing-small);
      }
    }
  }
}
