.containerDetailPro {
  display: flex;
  flex-flow: column wrap;
  width: calc(100vw - 25rem);
  max-width: calc(100vw - 24rem);
  height: calc(100%);
  padding: 3rem;
  background-color: rgb(252, 252, 252);
  .firstSection {
    justify-content: flex-start;
    img {
      cursor: pointer;
    }
  }
  .secondSection {
    background-color: white;
    padding: 2rem;
    margin-top: 2rem;
    border-radius: 1rem;
    border: 1px solid rgba(222, 222, 222, 0.2);
    .firstLine {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .buttons {
        display: flex;
        flex-flow: row wrap;
        cursor: pointer;
        img:last-child {
          margin-left: 2rem;
        }
      }
    }
    .secondLine {
      display: flex;
      flex-flow: column wrap;
      margin: 2rem 0rem 0rem 1rem;
      p:not(:first-child) {
        margin-top: 1.5rem;
      }
      p {
        h6 {
          font-weight: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 835px) {
  .containerDetailPro {
    width: 100%;
    max-width: 100%;
  }
}
