@import "~styles";
.inputTextBox {
  position: relative;
  width: 100%;
  @extend .flex-juststart-column;
  &.disabled {
    input {
      pointer-events: none;
      color: rgba(11, 26, 50, 0.4);
    }
  }
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
}
