@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;600;700&Roboto:wght@300;400;500;700;900&display=swap");
:root {
	--main-black: #1c1c1c;
	--main-white: #fff;
	--main-gray: #d9dbe1;
	--main-blue: #0b1a32;
	--main-blue-opacity: rgba(11, 26, 50, 0.48);
	--main-background: #564592;
	--secondary-background: #724cf9;
	--secondary-blue: #320fcc;
	--secondary-gray: #6f7482;
	--secondary-white: #fbfbfb;
	--terciary-gray: #f3f3f3;
	--color-error: #ff4179;
	--color-primary: #04d4c0;
	--color-gray: #f8f8f8;
	// --color-item: #ff6e5d;
	--color-item: #ff8671;
	--color-item-background: #ffcfc6;
	--border-gray: rgba(11, 26, 50, 0.04);
	--border-error: #ff0c3e;
	--border-valid: #56c662;
	--button-background: linear-gradient(
		86.44deg,
		#320fcc 0.51%,
		#3b0fff 121.26%
	);
	--font-xs: 1.2rem;
	--font-s: 1.4rem;
	--font-m: 1.6rem;
	--font-xm: 1.8rem;
	--font-l: 2rem;
	--font-l-1: 2.2rem;
	--font-xl: 2.4rem;
	--font-xl-1: 3.2rem;
	--font-xxl: 3.6rem;
	--font-xxxl: 4.2rem;
	--w-light: 300;
	--w-regular: 400;
	--w-medium: 500;
	--w-semibold: 600;
	--w-bold: 700;
	--w-extrabold: 800;
	--spacing-extrasmall: 1rem;
	--spacing-small: 1.6rem;
	--spacing-medium: 2.4rem;
	--spacing-large: 3rem;
	--spacing-xlarge: 4rem;
	--border-radius: 6px;

	.Toastify__toast {
		width: 304px;
		background: var(--secondary-white);
		box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
    padding: 1.4rem;
	}
	.Toastify__close-button {
		display: none;
	}
	.Toastify__progress-bar {
		background: var(--color-item);
	}
}

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 62.5%;
	font-family: "Open Sans", sans-serif;
	font-weight: var(--w-regular);
	color: var(--main-blue);
}

body {
	box-sizing: border-box;
	overflow-x: hidden;
}

a,
input {
	text-decoration: none;
	outline: none;
}

button {
	border: none;
	outline: none;
}
