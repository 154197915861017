.container {
  max-width: 80rem;
  margin: 0 auto;
  padding: var(--spacing-xlarge) 0;
}

.section {
  margin: 2.4rem 0;
}

@media screen and (max-width: 835px) {
  .container {
    padding: 0 var(--spacing-small);
  }
}
