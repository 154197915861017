@import "~styles";
.container {
  @extend .opensans;
  padding: 40px 0 10px;
  background: var(--border-gray);
  background: rgba(214, 214, 214, 1);
  display: flex;
  flex-wrap: wrap;
  margin-top: 30rem;
  min-height: 420px;

  &.noDistractionsMode {
    display: none;
  }

  .smallContent {
    width: 30%;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
    min-height: 245px;
    margin-top: 0px;
    .firstUlColumn {
      ul {
        list-style: none;
        li {
          margin-top: 1rem;
        }
      }
    }
  }
  .bigContent {
    .column:not(:last-child) {
      width: 30%;
    }
    .column:last-child() {
      width: 40%;
    }
    width: 70%;
    display: flex;
    margin: auto;
    li {
      list-style: none;
      margin: 12px 0;
    }
    h2 {
      font-size: var(--font-l);
      color: var(--main-blue);
      line-height: 30px;
    }
  }
  h3 {
    font-size: var(--font-m);
    color: var(--main-blue);
    line-height: 26px;
    font-weight: var(--w-regular);
    .mail {
      color: #ff6e5d;
    }
    &.contactoText {
      max-width: 235px;
      &:hover {
        text-decoration: none;
      }
    }
    &:hover {
      text-decoration: underline;
    }
  }
  h4 {
    font-size: var(--font-s);
    font-weight: var(--w-regular);
    text-align: center;
  }
  .bottomContent {
    width: 85%;
    margin: auto;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    border-top: 1px solid #313d5532;
    padding-top: 24px;
  }
  .paisanos {
    width: fit-content;
    display: flex;
    a {
      text-decoration: none;
      color: var(--main-blue);
    }
    svg {
      margin-right: 8px;
    }
  }
  .column {
    display: flex;
    flex-flow: column wrap;
    .contactoTitle {
      display: none;
    }
    margin: 0 35px;
    #titlePros {
      color: var(--secondary-blue);
    }
    #titleUsers {
      color: var(--color-item);
    }
  }
  .list {
    margin-top: 1rem;
  }

  .footerLink {
    text-decoration: none;
  }

  .redes {
    margin-bottom: 1rem;
    a {
      margin: 0 12px;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 835px) {
  .container {
    border: 1px solid black;
    background: #dedede;
    display: flex;
    flex-direction: column;
    padding: 24px 0 10px;
    .smallContent {
      width: 90%;
      min-height: 130px;
    }
    .bigContent {
      margin-top: var(--spacing-large);
      width: 100%;
      flex-direction: column;
    }
    .bottomContent {
      width: 75%;
      height: 100px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .column {
      margin: 0 40px 40px;
      h2 {
        position: relative;
        border-bottom: 2px solid var(--main-black);
        padding-bottom: var(--spacing-small);
      }
      .contactoTitle {
        visibility: hidden;
      }
      h2.contactoTitle::after {
        background: none;
      }
      h2.contactoTitle {
        color: var(--color-item);
        border-bottom: 2px solid var(--color-item);
        padding-bottom: var(--spacing-small);
      }
    }
    img {
      margin-top: 1rem;
      border-radius: 8px;
    }
    h4 {
      font-size: var(--font-xs);
    }
    .list {
      pointer-events: auto;
      margin-top: 2.5rem;
      height: 150px;
      opacity: 1;
      h3 {
        font-family: "Montserrat";
        font-size: var(--font-s);
        font-weight: var(--w-medium);
      }
      .mail {
        color: var(--main-black);
        font-weight: var(--w-extrabold);
      }
    }
    h3.contactoText {
      margin-top: var(--spacing-medium);
      max-width: unset;
      br {
        display: none;
      }
    }
  }
}
