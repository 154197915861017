.chatPage {
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 4rem auto;
  flex-direction: column;
}
.content {
  margin-top: 3.3rem;
  padding: 0 var(--spacing-small);
}
.chatContainer {
  min-width: 752px;
  background: rgba(222, 222, 222, 0.2);
  border: 1px solid rgba(11, 26, 50, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: var(--spacing-medium);
}
.messagesContainer {
  height: 320px;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}
.bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 2.4rem;
}
.fileClip {
  cursor: pointer;
  padding-right: var(--spacing-small);
  margin-right: 0.8rem;
}

.input {
  background: rgba(11, 26, 50, 0.06);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: var(--font-m);
  border: unset;
  -webkit-appearance: none;
  padding: 14.5px var(--spacing-medium);
  width: 73%;
  height: 4.8rem;
  margin-right: var(--spacing-small);
  &::placeholder {
    opacity: 0.2;
  }
}

@media screen and (max-width: 835px) {
  .chatPage {
    max-width: 90vw;
  }
  .content {
    padding: 0;
    margin-top: 0;
  }
  .chatContainer {
    min-width: unset;
    max-width: 100%;
  }
  .input {
    margin-left: auto;
    margin-right: 0;
    margin-bottom: var(--spacing-small);
    width: 80%;
    // max-width: 225px;
  }
  .fileClip {
    padding-left: var(--spacing-small);
    margin-bottom: var(--spacing-small);
    margin-right: 0;
  }
  .bottom {
    padding-top: var(--spacing-small);
  }
}

@media screen and (max-width: 320px) {
  .input {
    max-width: 192px;
  }
}
