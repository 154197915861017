@import "~styles";

.recoverPassword {
  width: 100%;
  margin: 0 auto;
  max-width: 510px;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 3.2rem;
  &_main {
    @extend .flex, .direction-column, .just-center, .align-center;
  }
  .passwordMessage {
    display: block;
    background-color: rgb(222, 222, 222);
    padding: 1.1rem 0.8rem 1.1rem var(--spacing-xlarge);
    color: var(--main-blue);
    font-size: var(--font-xs);
    position: relative;
    border-radius: 4px;
    width: 100%;
    margin-top: 0.8rem;
    margin-bottom: 1.6rem;
    opacity: 0.6;
    &:after {
      position: absolute;
      top: 50%;
      left: 2.5%;
      transform: translate(0%, -50%);
      display: block;
      content: "";
      background: url("../../../assets/svg/alert.svg") no-repeat;
      background-position: center;
      background-size: 100%;
      fill: #323232;
      width: 18px;
      height: 18px;
    }
  }
  .forgotPassword {
    font-size: var(--font-s);
    margin-bottom: var(--spacing-xlarge);
    color: var(--secondary-blue);
    font-weight: var(--w-regular);
  }
}

@media screen and (max-width: 835px) {
  .recoverPassword {
    border: none;
  }
}
