.listContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  .optionsContainer {
    &.opened {
      .optionsHeader {
        border-bottom: 1px solid rgba(11, 26, 50, 0.1);
      }
    }
  }
  .optionsHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--spacing-small);
    margin-top: var(--spacing-medium);
    .optionName {
      font-size: var(--font-m);
      color: var(--secondary-gray);
      font-weight: var(--w-regular);
    }
    .optionsOpenHandler {
      display: flex;
      align-items: center;
      cursor: pointer;
      p {
        font-size: var(--font-m);
        color: var(--secondary-blue);
      }
      .icon {
        cursor: pointer;
        width: 1.2rem;
        height: 1.2rem;
        fill: var(--secondary-blue);
        margin-left: 1rem;
        transition: all 350ms ease-in-out;
        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }
  .subServiceList {
    .subService {
      display: flex;
      align-items: center;
    }
    .optionSoon {
      display: block;
      background: rgba(255, 110, 93, 0.3);
      border-radius: 4px;
      color: #ff6e5d;
      font-weight: var(--w-bold);
      font-size: var(--font-xs);
      margin-left: var(--spacing-small);
      padding: 5px 8px;
    }
  }
}
