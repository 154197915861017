.container {
  position: absolute;
  top: 50%;
  left: 83%;
  // border: 1px solid orangered;
  transform: translateY(-50%);
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 25px;
  height: 15px;
  cursor: pointer;
  padding: 2rem;
  box-sizing: content-box;
}

.line {
  display: block;
  width: 100%;
  background-color: var(--color-item);
  height: 2.5px;
  transition: transform 350ms, width 350ms;
}
.lastLine {
  display: block;
  width: 50%;
  background-color: var(--color-item);
  height: 2.5px;
  transform: translate(-5.5px,0);
  transition: transform 350ms, width 350ms;
}
.firstOpened {
  transform: rotate(45deg) translate(5px, 4px);
  width: 110%;
}
.lastOpened {
  transform: rotate(-45deg) translate(5px, -4px);
  width: 110%;
}
.hideOpened {
  opacity: 0;
}

@media screen and (min-width: 835px) {
  .container {
    display: none;
  }
}
