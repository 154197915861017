.wrapper {
  display: flex;
  align-items: center;
}
.icon {
  margin-left: var(--spacing-small);
}
.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: min-content;
  width: 100%;
  background: rgba(222, 222, 222, 0.2);
  padding: 8px;
  padding-bottom: 0;
  min-height: 5.6rem;
  .input {
    background-color: transparent;
    font-size: var(--font-m);
    -webkit-appearance: none;
    border: none;
    padding-bottom: 8px;
    padding-left: 8px;
    min-height: 3.8rem;
    &::placeholder {
      opacity: 0.2;
      font-weight: var(--w-regular);
    }
    &:focus {
      border: unset;
      outline: none;
    }
    &:disabled {
      opacity: 0.4;
    }
  }
}

@media screen and (max-width: 835px) {
  .wrapper {
    align-items: flex-start;
    flex-direction: column;
  }
  .icon {
    width: 90%;
  }
}
