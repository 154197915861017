.messageContainer {
  width: 100%;
  background-color: rgba(222, 222, 222, 0.2);
  border-radius: 8px;
  padding: var(--spacing-medium);
  display: flex;
  flex-direction: column;
  transition: all 350ms ease-in-out;
  opacity: 1;
  height: auto;
  &.closed {
    height: 0;
    opacity: 0;
    padding: 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .close {
      cursor: pointer;
    }
  }
  .body {
    margin-top: 2.4rem;
    display: flex;
    .checkList {
      display: flex;
      flex-direction: column;
      margin-left: var(--spacing-medium);
      .checkItem {
        font-size: var(--font-m);
        display: flex;
        align-items: center;
        margin: 0.4rem 0;
        &::before {
          content: "";
          display: block;
          background: url("../../../assets/svg/check-green.svg") no-repeat;
          width: 1.8rem;
          height: 1.8rem;
          margin-right: 0.8rem;
        }
      }
    }
  }
}
