.slideButton {
  display: flex;
  overflow: hidden;
  background-color: #ffffff;
  margin-left: 1.63rem;
  cursor: pointer;
  .icon {
    margin-right: 0.88rem;
  }
}
