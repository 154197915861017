.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	.title {
		font-size: var(--font-l-1);
		font-weight: var(--w-semibold);
		margin-bottom: 3.2rem;
	}
	.radioButtonGroup {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		min-height: 200px;
		max-height: 480px;
		&.noMaxHeight {
			max-height: unset;
		}
	}
	.otherField {
		display: flex;
		align-items: center;
		.inputOther {
			width: 100%;
			border: none;
			background-color: rgba(222, 222, 222, 0.2);
			padding: var(--spacing-small);
			font-size: var(--font-m);
			border-radius: 4px;
			transition: opacity 100ms, all 500ms ease-in-out;
			&::placeholder {
				opacity: 0.2;
				font-weight: var(--w-regular);
			}
			&:disabled {
				width: 0;
				opacity: 0;
			}
		}
	}
}

@media screen and (max-width: 720px) {
	.container {
		.title {
			font-size: var(--font-m);
		}
		.radioButtonGroup {
			flex-wrap: nowrap;
			overflow: auto;
		}
	}
}
