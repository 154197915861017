.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  .starsContainer {
    margin: calc(var(--spacing-small) * 2) 0 var(--spacing-small);
    display: flex;
    align-items: center;
    .star {
      cursor: pointer;
      height: 4.3rem;
      display: flex;
      justify-content: center; 
      align-items: center;
    }
    .starIcon {
      transition: opacity 300ms ease-in-out;
      &.starIcon-1 {
        opacity: 0.2;
      }
      &.starIcon-2 {
        opacity: 0.4;
      }
      &.starIcon-3 {
        opacity: 0.6;
      }
      &.starIcon-4 {
        opacity: 0.8;
      }
      &.starIcon-5 {
        opacity: 1;
      }
    }
  }
}
