@import "~styles";

.textarea {
  background: rgba(222, 222, 222, 0.2);
  border: none;
  border-radius: 4px;
  font-size: var(--font-m);
  @extend .opensans;
  padding: 1.2rem;
  width: 100%;
  min-height: 225px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    opacity: 0.3;
  }
  resize: none;
}
