.valoration {
  display: flex;
  padding-bottom: var(--spacing-medium);
  border-bottom: 1px solid rgba(11, 26, 50, 0.1);
  margin-top: var(--spacing-medium);
  width: 100%;
  .noImage {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px dashed rgba(11, 26, 50, 0.2);
    background: rgba(222, 222, 222, 0.5);

    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    .icon {
      width: 50%;
      height: 50%;
    }
  }
  .left {
    margin-right: 3.2rem;
    display: grid;
    grid-template-columns: 6rem 15rem;
    grid-template-rows: max-content 2.4rem;
    .image {
      grid-column: 1 / span 1;
      grid-row: 1 / span 2;
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 50%;
      object-fit: cover;
      img {
        width: 100%;
      }
    }
    .name {
      grid-row: 1 / span 1;
      grid-column: 2 / span 1;
      font-size: var(--font-m);
      font-weight: var(--w-bold);
    }
    .row {
      grid-row: 2 / span 1;
      grid-column: 2 / span 1;
      display: flex;
      align-items: center;
      .value {
        font-size: var(--font-m);
        font-weight: var(--w-bold);
        color: #f4ac39;
        display: flex;
        align-items: center;
        margin-right: 0.8rem;
        &::before {
          content: "";
          display: block;
          background: url("../../../assets/svg/star.svg") no-repeat;
          background-size: 100%;
          background-position: center;
          width: 1.6rem;
          height: 1.6rem;
        }
      }
      .date {
        font-size: var(--font-xs);
      }
    }
  }
}

@media screen and (max-width: 835px) {
  .valoration {
    flex-direction: column;
    .left {
      margin-bottom: var(--spacing-small);
    }
  }
}
