@import "~styles";

.stepContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  div:nth-child(1),
  div:nth-child(3) {
    width: 10%;
  }
  div:nth-child(2),
  div:nth-child(4) {
    width: 85%;
  }
  .sectionFirst {
    width: 10%;
    color: var(--secondary-blue);
    text-align: center;
    line-height: 5rem;
    font-size: 2rem;
    border-radius: 50%;
    height: fit-content;
    cursor: pointer;
    span{
      border: 1px solid var(--secondary-blue);
      border-radius: 50%;
      padding: 0.7rem 1.5rem 0.7rem 1.5rem;
    }
    .visto{
      background: #6aca4f;
      border: 1px solid #6aca4f;
      border-radius: 50%;
    }
    span,
    .visto{
      width: 4rem !important;
      height: 4rem !important;
    }
  }
  .sectionTwo {
    width: 85%;
    display: flex;
    flex-flow: column wrap;
    cursor: pointer;
    span:nth-child(1) {
      font-weight: bold;
      color: rgb(67, 67, 67);
    }
    span {
      font-size: 1.8rem;
    }
  }
  .sectionThree {
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    div {
      height: 16rem;
      width: 0.2rem;
      background-color: rgb(255, 134, 113);
    }
  }
  .sectionFourth {
    height: fit-content;
    margin-top: 2rem;
    .reactPlayer{
      max-height: 40% !important;
    }
  }
}
