.checkboxContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    cursor: auto;
    .box {
      background-color: rgba(222, 222, 222, 0.4);
      border: 1px solid #dedede;
      &.checked {
        opacity: 0.2;
      }
    }
  }
  .optionSoon {
    display: block;
    background: rgba(255, 110, 93, 0.3);
    border-radius: 4px;
    color: #ff6e5d;
    font-weight: var(--w-bold);
    font-size: var(--font-xs);
    margin-left: var(--spacing-small);
    padding: 5px 8px;
  }
  .box {
    height: 2.4rem;
    width: 2.4rem;
    background-color: var(--main-white);
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    transition: background-color 350ms;
    margin-right: 1.2rem;
    &.checked {
      background: url("../../../assets/svg/check.svg") no-repeat center;
      background-color: var(--secondary-blue);
      border: none;
    }
  }
}
