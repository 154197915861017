@import "~styles";

.input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid transparent;
  text-align: left;
  @extend .base-font, .grayBackground;
  padding: 1.3rem 1.2rem;
  background-color: rgba(222, 222, 222, 0.2);
  color: var(--main-blue);
  &.invalid {
    @extend .invalid;
  }
  &.valid {
    @extend .valid;
  }
  &.hasIcon {
    padding-left: var(--spacing-large);
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: var(--main-blue);
    opacity: 0.2;
    @extend .opensans;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(249, 249, 249) inset !important;
    -webkit-text-fill-color: var(--main-black) !important;
    filter: none;
  }
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type="number"] {
  -moz-appearance: textfield;
}
.viewPassword {
  position: absolute;
  left: 92%;
  top: 35px;
}
