.container {
  width: 100vw;
  max-width: 52.8rem;
  box-sizing: border-box;
  .footer {
    padding-top: var(--spacing-medium);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media screen and (max-width: 835px) {
  .container {
    max-width: 80vw;
  }
}
