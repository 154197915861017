.proReputationContainer {
  position: absolute;
  left: 175px;
  top: 27.5px;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  h6 {
    font-size: var(--font-m);
    font-weight: var(--w-regular);
    b {
      color: var(--main-blue);
      font-weight: var(--w-regular);
      margin-left: 0.5rem;
      opacity: 0.4;
    }
  }
  .proReputationNumber {
    color: #f4ac39;
    padding-left: 0.25rem;
    display: flex;
    align-items: center;
    font-weight: var(--w-bold);
    &::before {
      content: "";
      display: block;
      background: url("../../../assets/svg/star.svg") no-repeat;
      background-position: center;
      background-size: 100%;
      height: 1.6rem;
      width: 1.6rem;
      margin-right: 0.25rem;
    }
  }
}
.viewAllContainer {
  margin: var(--spacing-medium) 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .viewAll {
    font-size: var(--font-m);
    text-align: center;
    cursor: pointer;
    color: var(--secondary-blue);
  }
  .icon {
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--secondary-blue);
    margin-left: 1rem;
    transition: all 350ms ease-in-out;
    &.open {
      transform: rotate(180deg);
    }
  }
}

.emptyState {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 835px) {
  .proReputationContainer {
    right: 16px;
    top: 16px;
    left: 140px;
    width: max-content;
  }
}
