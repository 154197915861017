.socialButton {
  width: 100%;
  border: 2px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0;
  margin: 0.8rem 0;
  cursor: pointer;
  .socialButtonText {
    font-size: var(--font-m);
    font-weight: var(--w-bold);
    color: var(--main-blue);
    margin-left: 0.8rem;
    opacity: 0.4;
  }
}
