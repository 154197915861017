@import "~styles";

.filter {
  position: relative;
  width: 22rem;
  margin-right: 2.4rem;
  .selectedOption {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1.6rem;
    border-radius: 4px;
    // box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
    //   0px 4px 24px rgba(51, 51, 51, 0.24);
    box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.04),
      0px 4px 16px rgba(51, 51, 51, 0.08);
    &::after {
      content: "";
      display: inline-block;
      background: url("../../../assets/svg/down-chevron.svg") no-repeat;
      background-size: 100%;
      background-position: center;
      width: 15px;
      height: 15px;
      transition: all 500ms;
      margin-left: auto;
      margin-right: 0rem;
      opacity: 0.5;
    }
    &.opened::after {
      transform: translate(0%, -50%) rotate(180deg);
    }
    p {
      @extend .roboto;
      font-size: var(--font-m);
      color: rgba(17, 17, 17, 0.48);
    }
  }
  label {
    font-size: var(--font-m);
    color: var(--main-blue);
    position: relative;
    cursor: pointer;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    height: 2.4rem;
  }

  .optionsList {
    max-height: 25rem;
    overflow: auto;
    position: absolute;
    left: 0;
    top: 105%;
    background-color: var(--main-white);
    width: 100%;
    box-shadow: 0px 20px 14px rgba(0, 0, 0, 0.07);
    border-radius: 0px 0px 4px 4px;
    z-index: 9;
    cursor: pointer;
    &.optionsWidthAuto {
      width: max-content;
    }
    .filterOption {
      padding: 1.3rem 3rem;
      &:hover {
        background: #e9eced;
      }
    }
    .checkboxGroupOptions {
      padding: 1.3rem 3rem;
      .optionName {
        font-size: var(--font-s);
        opacity: 0.6;
        font-weight: var(--w-regular);
      }
    }
  }
}
