.tableJobs {
  thead {
    tr {
      th {
        min-width: 20rem;
      }
    }
  }
  tbody {
    tr {
      td {
        .done {
          background-color: #6aca4f;
          width: 2.2rem;
          border-radius: 50%;
          padding: 0.2rem;
        }
      }
    }
  }
}
