@import "~styles";
.radioButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: var(--spacing-medium);
  margin-right: var(--spacing-small);
  .circle {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    border: 1px solid #dedede;
    margin-right: 1.2rem;
    transition: all 350ms ease-in-out;
  }
  .label {
    @extend .roboto;
    font-size: var(--font-m);
    font-weight: var(--w-regular);
  }
  &.selected {
    .circle {
      border: 7px solid var(--secondary-blue);
    }
  }
}

@media screen and (max-width: 835px) {
  .radioButton {
    .label {
      word-wrap: break-word;
      max-width: 80%;
    }
  }
}
