@import "~styles";

.TextPrimary {
  color: var(--main-blue);
  transition: font-size 300ms;
  @extend .opensans;
  &.xs {
    font-size: var(--font-xs);
  }
  &.s {
    font-size: var(--font-s);
  }
  &.m {
    font-size: var(--font-m);
  }
  &.xm {
    font-size: var(--font-xm);
  }
  &.l {
    font-size: var(--font-l);
  }
  &.l-1 {
    font-size: var(--font-l-1);
  }
  &.xl {
    font-size: var(--font-xl);
  }
  &.xl-1 {
    font-size: var(--font-xl-1);
  }
  &.xxl {
    font-size: var(--font-xxl);
  }
  &.xxxl {
    font-size: var(--font-xxxl);
  }
  &.light {
    font-weight: 300;
  }
  &.regular {
    font-weight: 400;
  }
  &.semibold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
  &.extrabold {
    font-weight: 800;
  }
  &.black {
    color: var(--main-black);
  }
  &.white {
    color: var(--main-white);
  }
  &.error {
    color: var(--color-error);
  }
  &.secondary-blue {
    color: var(--secondary-blue);
  }
}
