.slideCheckboxContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-right: 2.4rem;
}
.reverse {
	flex-direction: row-reverse;
	.slideCheckboxName {
		margin-left: 0;
		margin-right: 0.8rem;
	}
}
.slideCheckboxName {
	font-size: var(--font-m);
	color: var(--main-blue);
	opacity: 1;
	margin-left: 0.8rem;
}
.slideCheckbox {
	width: 4.8rem;
	min-width: 4.8rem;
	height: 2.4rem;
	background: #0b1a32;
	opacity: 0.2;
	border-radius: 100px;
	padding: 0.4rem;
	transition: all 0.5s;
	.slideCheckboxSelect {
		display: block;
		width: 1.6rem;
		height: 1.6rem;
		border-radius: 50%;
		background: linear-gradient(45deg, #ffffff 50%, #c4c4c4);
		transition: transform 0.35s;
	}
	&.unchecked {
		.slideCheckboxSelect {
			&:after {
				// content: "";
				// display: block;
				// background: var(--background-after);
				// background-position: center;
				// background-size: 100%;
				// width: 1.6rem;
				// height: 1.6rem;
				// position: relative;
				right: -125%;
			}
		}
	}
	&.checked {
		background-color: var(--secondary-blue);
		opacity: 1;
		.slideCheckboxSelect {
			transform: translateX(150%);
			position: relative;
			&:before {
				// content: "";
				// display: block;
				// background: var(--background-before);
				// background-position: center;
				// background-size: 100%;
				// width: 1.6rem;
				// height: 1.6rem;
				// position: absolute;
				left: -125%;
			}
		}
	}
}

@media screen and (max-width: 835px) {
	.slideCheckboxContainer {
		width: 100%;
		flex-direction: row-reverse;
		justify-content: space-around;
		margin-right: 0;
		margin-bottom: 1.6rem;
	}
	.slideCheckboxName {
		margin-left: 0;
	}
}
