.workModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 43rem;
  min-height: 39.5rem;
  &.step {
    min-height: max-content;
    width: 51rem;
  }
  .workIcon {
    margin: auto;
  }
  .workButtons {
    display: flex;
    align-items: center;
    width: 100%;
    margin: var(--spacing-small) 0 0;
  }
}
.requestModal {
  display: flex;
  flex-direction: column;
  width: 64rem;
  min-height: 39.5rem;
}
.sortContent {
  margin: var(--spacing-small) 0;
  .sortIcon {
    margin-right: 0.6rem;
  }
}
.sortModal {
  width: 79vw;
}
.contactInput {
  background: rgba(222, 222, 222, 0.2);
  border-radius: 4px;
  color: var(--main-blue);
  font-size: var(--font-m);
  height: 4.8rem;
  width: 100%;
  -webkit-appearance: none;
  outline: none;
  border: none;
  padding: 1.2rem;
  margin: 0.8rem 0;
  &::placeholder {
    opacity: 0.6;
  }
}

@media screen and (max-width: 835px) {
  .workModal {
    width: 75vw;
    .workButtons {
      flex-wrap: wrap;
    }
  }
  .requestModal {
    width: 85vw;
    max-height: 70vh;
    overflow-y: auto;
  }
}
