.companyRegisterContainer {
  width: 64rem;
  border: 1px solid rgba(11, 26, 50, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 3.2rem;
  .form {
    padding: 0 4.8rem 3.2rem;
    border-bottom: 1px solid rgba(11, 26, 50, 0.1);
  }
  .registerButtons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 3.2rem;
  }
  .dropdownContainer {
    margin-top: 3.2rem;
    .dropdownLabel {
      display: block;
      font-size: var(--font-m);
      margin-left: 1rem;
      margin-bottom: var(--spacing-small);
    }
  }
}

@media screen and (max-width: 835px) {
  .companyRegisterContainer {
    width: 100%;
    border: none;
    .form {
      padding: 0 0 3.2rem;
    }
  }
}
