.imageUploadContainer {
  width: 14.4rem;
  height: 14.4rem;
  border: 2px dashed rgba(11, 26, 50, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 3.2rem auto;
  position: relative;
  .errorMessage {
    color: var(--color-error);
    font-size: var(--font-s);
    position: absolute;
    top: 110%;
    white-space: nowrap;
    left: -35%;
  }
  &.newImage {
    border: none;
    border-radius: 10px;
  }
  .label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 4;
  }
  .add {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    fill: var(--main-blue);
  }
  .image {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  .deleteImage {
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    font-size: var(--font-xs);
    font-weight: var(--w-bold);
    cursor: pointer;
    &::before {
      content: "";
      display: block;
      width: 2rem;
      height: 2rem;
      background: url("../../../assets/svg/delete.svg") no-repeat;
      background-position: center;
      background-size: 100%;
      margin-right: 1rem;
    }
  }
}

.messageContainer {
  display: flex;
  padding: 0.8rem;
  margin: 0.8rem;
  background-color: #e7f3ff;
  border-radius: 4px;
  .icon {
    fill: var(--main-blue);
  }
  .text {
    display: flex;
    flex-direction: column;
    padding-left: 0.8rem;
    .paragraph {
      font-size: var(--font-xs);
      b {
        font-weight: var(--w-bold);
      }
    }
  }
}
