.buttons {
  margin-top: var(--spacing-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  &.end {
    justify-content: flex-end;
  }
}
.container {
  width: 372px;
}

.textArea {
  margin-top: var(--spacing-medium);
}

@media screen and (max-width: 835px) {
  .container {
    width: 80vw;
  }
}
