.container {
	display: flex;
	flex-direction: column;
	max-height: 400px;
	overflow: auto;
	&.noMaxHeight {
		max-height: none;
	}
	&.profileServiceOptions {
		max-height: none;
		border: 1px solid var(--main-gray);
		border-radius: val(--border-radius);
	}
	&.editProfileSubservices {
    max-height: none;
    display: inline;
		margin-top: 1rem;
		margin-right: 2rem;
		border: 1px solid var(--main-gray);
		border-radius: 15px;
	}
	.optionValue {
    margin: 0.8rem 0 0.5rem 0;
		width: 100%;
	}
}

.subServiceText {
  font-weight: var(--w-regular);
  font-size: var(--font-s)
}

.subServiceContainer{
  display: inline-flex;
  width: 290px;
  justify-content: right;
}

@media screen and (max-width: 835px) {
	.container {
		max-height: 65vh;
		&.editProfileSubservices {
			display: inline;
			margin-top: 4.5rem;
		}
	}
}
