.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--main-blue);
  opacity: 0.6;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 9999;
}
.wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  padding: var(--spacing-medium);
  z-index: 99999;
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2.4rem;
    cursor: pointer;
    padding: 1rem;
    box-sizing: content-box;
  }
}

@media screen and (max-width: 835px) {
  .wrapper {
    padding: var(--spacing-small);
  }
}
