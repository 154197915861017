.container {
  display: flex;
  flex-direction: column;
  .row,
  .nameContactType {
    display: flex;
    align-items: center;
    &.wrap {
      flex-wrap: wrap;
    }
  }
  .alias {
    color: gray;
    font-style: normal;
    margin-bottom: 2.5rem;
  }
  .topRow {
    display: flex;
    align-items: center;
  }
  .zenCoin {
    margin-left: auto;
  }
  .name {
    font-size: var(--font-l);
    margin-right: var(--spacing-small);
  }
  .status {
    margin-left: auto;
  }
  .status,
  .contactType {
    font-size: var(--font-xs);
    font-weight: var(--w-bold);
    display: block;
    padding: 0.4rem 0.8rem;
    border: 1px solid #b5b5b5;
    color: #b5b5b5;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #fff;
    text-transform: uppercase;
    &.contacto-telefonico,
    &.new {
      background-color: rgba(106, 0, 204, 0.08);
      border-color: #6a00cc;
      color: #6a00cc;
    }
    &.contacto-por-mensaje {
      background-color: rgba(121, 250, 135, 0.14);
      border-color: #02680d;
      color: #02680d;
    }
    &.approved,
    &.finished,
    &.work_completed {
      border-color: #02680d;
      color: #02680d;
    }
    &.pending {
      border-color: #723f09;
      color: #723f09;
    }
    &.rejected,
    &.not_finished,
    &.work_not_completed {
      border-color: #ff4949;
      color: #ff4949;
    }
  }
  .coin {
    width: 2.4rem;
    height: 2.4rem;
  }
  .coinValue {
    font-size: var(--font-l);
    margin-left: 0.8rem;
    font-weight: var(--w-bold);
    color: var(--secondary-blue);
  }
  .dataText {
    font-size: var(--font-s);
    margin: 0.6rem 0.8rem;
  }
  .servicesList {
    margin-top: var(--spacing-small);
    p {
      font-size: var(--font-s);
      margin-bottom: 0.8rem;
    }
    .service {
      font-size: var(--font-m);
      margin-bottom: 0;
    }
  }
  .messages {
    margin-top: var(--spacing-small);
    p {
      font-size: var(--font-s);
      margin-bottom: 0.8rem;
    }
    .messageContent {
      padding: var(--spacing-medium);
      background-color: #fafafa;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      border-radius: 6px;
      p {
        font-size: var(--font-m);
        margin-bottom: 0;
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
  }
  .qualification {
    margin-top: var(--spacing-small);
    p {
      font-size: var(--font-s);
      margin-bottom: 0.8rem;
    }
    .qualificationValue {
      display: flex;
      align-items: center;
      svg {
        margin: 0 0.4rem;
      }
      p {
        font-size: var(--font-xm);
        margin-bottom: 0;
        &.value {
          color: #f4ac39;
          font-weight: var(--w-bold);
        }
      }
    }
    .message {
      font-size: var(--font-m);
      margin-top: 0.8rem;
      margin-bottom: 0;
      word-wrap: break-word;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .buttons {
    margin-top: var(--spacing-medium);
  }
  .openController {
    width: 100%;
    border-top: 1px solid rgba(11, 26, 50, 0.1);
    margin-top: var(--spacing-large);
    padding-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: var(--secondary-blue);
      font-size: var(--font-m);
      font-weight: var(--w-regular);
      cursor: pointer;
    }
    .icon {
      fill: var(--secondary-blue);
      width: 1.6rem;
      margin-left: 0.8rem;
      transition: all 350ms ease-in-out;
      &.opened {
        transform: rotate(180deg);
      }
    }
  }
}
.notAvailable {
  display: flex;
  align-items: center;
  background: linear-gradient(
    271.68deg,
    rgba(255, 110, 93, 0.8) 1.64%,
    rgba(221, 39, 39, 0.8) 99.01%
  );
  svg {
    margin-right: var(--spacing-small);
  }
  border-radius: 8px 8px 0px 0px;
  padding: var(--spacing-medium);
  .row {
    display: flex;
    align-items: center;
  }
}

.worksLength {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background-color: var(--border-error);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-white);
  font-size: var(--font-xs);
  font-weight: var(--w-bold);
  transition: all 350ms ease-in-out;
  margin-left: var(--spacing-small);
}

@media screen and (max-width: 835px) {
  .container {
    .row {
      flex-wrap: wrap;
    }
    .servicesList {
      margin-bottom: 0.8rem;
      .service {
        word-wrap: break-word;
        max-width: 80vw;
      }
    }
    .zenCoin {
      width: 65%;
    }
    .coinValue {
      font-size: var(--font-m);
    }
    .topRow {
      align-items: flex-start;
      .status {
        width: max-content;
      }
    }
    .nameContactType {
      flex-wrap: wrap;
      align-items: flex-start;
      .name {
        max-width: 65%;
        word-wrap: break-word;
      }
      .status {
        margin-left: 0;
      }
      .contactType {
        margin-top: var(--spacing-small);
      }
    }
  }

  .phoneCall {
    width: 100%;
    display: flex;
    height: 4.8rem;
    align-items: center;
    justify-content: center;
    color: var(--secondary-blue);
    border: 1px solid rgba(11, 26, 50, 0.2);
    border-radius: 6px;
    font-size: var(--font-m);
    font-weight: var(--w-bold);
    margin: var(--spacing-small) 0;
  }
  .notAvailable {
    flex-direction: column;
    .row {
      margin-bottom: calc(var(--spacing-small) * 2);
    }
    svg {
      width: 70px;
    }
  }
}

@media screen and (max-width: 360px) {
  .container {
    .row {
      margin-left: 0 !important;
    }
  }
}
