.CalendarDay__default,
.CalendarDay__default:hover {
  border: none;
  border-radius: 50%;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: var(--secondary-blue);
}

.DayPicker_transitionContainer,
.DayPicker__withBorder {
  border-radius: 12px;
}
.SingleDatePickerInput__withBorder {
  border: unset;
}
.SingleDatePicker_picker {
  position: relative;
  top: 1px !important;
}
.DateInput_fang,
.DateInput_screenReaderMessage,
.DateInput,
.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}
.DayPicker_weekHeader_li {
  color: var(--main-blue);
  font-weight: var(--w-bold);
}
.CalendarMonth_caption {
  text-align: left;
  margin-left: 10px;
}
.DayPickerNavigation_button__default,
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: none;
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 220px;
}
.DayPickerNavigation_leftButton__horizontalDefault svg {
  background: url("../../../assets/svg/down-chevron.svg") no-repeat center;
  background-size: 100%;
  height: 19px;
  width: 17px;
  transform: rotate(90deg);
}
.DayPickerNavigation_rightButton__horizontalDefault svg {
  background: url("../../../assets/svg/down-chevron.svg") no-repeat center;
  background-size: 100%;
  height: 19px;
  width: 17px;
  transform: rotate(-90deg);
}
.DayPickerNavigation_svg__horizontal {
  fill: transparent;
}

// .DateInput {
//   height: 0;
//   padding: 0;
// }


// Item numero dia
._1BZ_S {
  display: flex;
  border: 1px solid var(--main-white);
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 100%;
  box-shadow: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: opacity 0.15s, background-color 0.15s;
  border-radius: 50%;
  font-size: var(--font-s);
  align-self: center;
  justify-self: center;
}

._1BZ_S:hover {
  opacity: 0.6;
}

._1BZ_S._3tHh2 {
  height: 3.5rem;
}

._vqHRN {
  min-width: 23rem;
  width: 100%;
  min-height: 23rem;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 0.25rem;
  height: 13rem;
  text-align: center;
  justify-content: center;
  padding: 1px;
}
// Item nombre dia
._2O9sG {
  width: 100%;
  font-size: var(--font-xs);
  font-weight: var(--w-bold);
  padding-top: 0.4rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #505050;
}

// calendar

._Xg5X- {
  border: 1px solid var(--main-gray);
  border-radius: var(--border-radius);
  padding: var(--spacing-small);
  width: max-content;
  height: max-content;
  min-height: 30rem;
}

._2oyQ7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.25rem;
}

._2xL52 {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

._SSZ80 {
  display: none;
  justify-content: center;
  align-items: center;
  min-height: 24px;
  min-width: 24px;
  color: var(--secondary-gray);
  background-color: var(--main-white);
  border: 1px solid var(--secondary-blue);
  transition: opacity 0.15s;
  cursor: pointer;
  border-radius: var(--border-radius);
}

._SSZ80._2RWmX {
  opacity: 0.3;
  cursor: not-allowed;
}

._SSZ80:hover {
  opacity: 0.6;
}

._qtKCN {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0 0.25rem;
}

._1Kp6O {
  font-size: var(--font-m);
  font-weight: var(--w-semibold);
  transition: background-color 0.15s;
  padding: 0.1rem 0.25rem;
}

._1Kp6O._SXvCV {
  cursor: pointer;
}

._1Kp6O._SXvCV:hover {
  background-color: var(--main-gray);
}
