@import "~styles";

.login {
  width: 100%;
  margin: 0 auto;
  max-width: 510px;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 1.5rem 3.2rem 0 3.2rem;
  &_main {
    @extend .flex, .direction-column, .just-center, .align-center;
  }
  .forgotPassword {
    font-size: var(--font-s);
    margin-bottom: var(--spacing-xlarge);
    color: var(--secondary-blue);
    font-weight: var(--w-regular);
  }
  .bottomLink {
    width: 100%;
    margin-top: var(--spacing-medium);
    margin-bottom: var(--spacing-small);
    padding-top: var(--spacing-small);
    border-top: 1px solid rgba(11, 26, 50, 0.1);
    p {
      cursor: pointer;
    }
  }
  .phoneButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .loginSeparator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2.4rem 0;
    font-size: var(--font-s);
    font-weight: var(--w-regular);
    color: var(--secondary-gray);
    .separator {
      display: block;
      height: 1px;
      width: 10vw;
      background-color: rgba(227, 227, 227, 0.5);
      &:first-child {
        margin-right: 1rem;
      }
      &:last-child {
        margin-left: 1rem;
      }
    }
  }
  .termsAndConditions {
    font-size: var(--font-s);
    font-weight: var(--w-regular);
    color: var(--secondary-gray);
    margin-bottom: 1.6rem;
    margin-top: 0.8rem;
    text-align: center;
  }
  .registerFields {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    & > div:first-child {
      margin-right: 1.6rem;
    }
  }
  .registerButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 835px) {
  .login {
    border: none;
    padding: 0 var(--spacing-medium) var(--spacing-medium);
    .loginSeparator {
      .separator {
        width: 35vw;
      }
    }
    .termsAndConditions {
      margin-top: var(--spacing-medium);
      text-align: left;
    }
    .registerFields {
      flex-direction: column;
      & > div:first-child {
        margin-right: 0;
        margin-bottom: 1.6rem;
      }
    }
  }
}
