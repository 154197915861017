@import "~styles";
.container {
  // background-color: var(--secondary-blue);
  // background-color: var(--color-item);
  background-color: var(--main-white);
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 80px;
  border-bottom: 1px solid var(--border-gray);
  padding: var(--spacing-medium) 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.noDistractionsMode {
    .menuIconRef,
    .rightContent {
      display: none;
    }
  }
  &.loggedIn {
    padding: var(--spacing-medium) 0 var(--spacing-medium) 64px;
  }
}
.rightContent {
  display: flex;
  align-items: center;
  .iniciar {
    box-shadow: 0px 0px 10px #ccc;
    width: 18rem;
    height: 4rem;
    line-height: 2.8rem;
    text-align: center;
    border-radius: 10rem;
  }
  .perfilMenu {
    margin-top: 0.5rem !important;
    width: 70%;
    span {
      border-radius: 20px;
      font-size: 1.5rem !important;
    }
    .labelProfileMenu {
      display: flex;
      align-items: center;
      img {
        margin-left: 1rem;
      }
      span {
        margin-left: 0.5rem;
        padding: 0px;
      }
    }
    .labelSolicitudesMenu {
      display: flex;
      align-items: center;
      margin-top: var(--spacing-small);
      border: 1px solid white;
      padding: 8px 0px;
      img {
        margin-left: 1rem;
      }
      span {
        border-radius: 20px;
        margin-left: 0.5rem;
        padding: 0px;
      }
    }
  }
}

.menuInfo {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  color: white;
  margin-bottom: 2rem;
  font-family: "Montserrat";
  span:nth-child(2) {
    font-weight: bolder;
  }
}
.labelCloseSession {
  width: 70% !important;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 25px;
}
.link {
  display: block;
  text-decoration: none;
  white-space: nowrap;
  &.spacing {
    margin: 0 12px 0 24px;
    @media screen and (max-width: 835px) {
      margin: 14px 24px;
    }
  }
  span {
    display: block;
    @extend .opensans, .mainBackground, .base-font;
    padding: 5px 0;
    width: 100%;
    // background: var(--main-white);
    background: transparent;
    color: var(--main-black);
    font-weight: var(--w-regular);
    position: relative;
    transition: all 350ms ease-in-out;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 0px;
      height: 3px;
      // background-color: var(--secondary-blue);
      background: transparent;
      transition: all 350ms ease-in-out;
    }
  }
}
.buttonLink {
  color: var(--main-white);
}
.menuIconRef {
  display: none;
}

.active {
  span {
    color: var(--secondary-blue);
    font-weight: var(--w-bold);
    &::after {
      width: 100%;
    }
  }
}
.logoContainer {
  cursor: pointer;
  // svg {
  //   width: 70%;
  // }
  transform: scale(1.2);
}
.separator {
  width: 1px;
  height: 48px;
  background: var(--border-gray);
  margin-left: var(--spacing-small);
}
.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  padding-right: 64px;
  min-width: 30rem;
  .separator {
    width: 1px;
    height: 48px;
    background: var(--border-gray);
    margin: 0 40px 0 0px;
  }
  &Menu {
    border: 1px solid var(--border-gray);
    border-top: none;
    width: 100%;
    border-radius: 20px;
    position: absolute;
    top: 90px;
    left: -40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    box-shadow: 3px 4px 14px rgba(0, 0, 0, 0.054);
    @media screen and (max-width: 835px) {
      position: static;
      box-shadow: none;
      border: none;
    }
    &_item {
      display: flex;
      align-items: center;
      justify-content: left;
      padding: 16px 30px;
      &:first-child {
        border-bottom: 1px solid var(--border-gray);
      }
      svg {
        margin-right: 8px;
      }
    }
  }
}

.icon {
  width: 1.2rem;
  height: 1.2rem;
  fill: white;
  margin-left: 1rem;
  transition: all 350ms ease-in-out;
  &.open {
    transform: rotate(180deg);
  }
}

.worksLength {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background-color: rgba(11, 26, 50, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-white);
  font-size: var(--font-xs);
  font-weight: var(--w-bold);
  transition: all 350ms ease-in-out;
  margin-right: 0.8rem;
}

@media screen and (max-width: 835px) {
  .logoContainer {
    cursor: pointer;
    transform: scale(1);
  }
  .container {
    // border: 1px solid orange;
    &.loggedIn {
      padding: var(--spacing-medium) 25px var(--spacing-medium) 64px;
    }
    &.noDistractionsMode {
      .menuIconRef,
      .rightContent {
        display: flex;
      }
      .menuText {
        display: flex;
        font-weight: var(--w-medium);
        font-size: var(--font-m);
        color: var(--color-item);
      }
    }
    .menuText {
      font-weight: var(--w-medium);
      font-size: var(--font-m);
      color: var(--color-item);
    }
    .menuContainer {
      display: flex;
      flex-direction: row;
      // border: 1px solid blue;
      // width: 90vw;
      align-items: center;
      // .divLogo{
      //   width: 80% !important;
      // }
      .menu {
        background-color: transparent;
        // width: 20% !important;
        // border: 1px solid orange;
        // display: flex;
        // flex-flow: row wrap;
        // align-items: center;
      }
    }
  }
  .menuIconRef {
    display: block;
  }
  // .logoContainer {
  //   position: absolute;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
  .buttonsContainer {
    width: 57%;
    display: flex;
  }
  .container {
    box-shadow: 3px 4px 20px 1px var(--main-gray);
    justify-content: space-between;
    padding: var(--spacing-medium) 26px;
    position: relative;
    background: var(--main-white);
  }
  .loggedIn {
    .container {
      justify-content: space-between;
    }
  }
  .avatar {
    margin-left: auto;
    // z-index: 1;
  }
  .profile {
    padding-right: 0;
  }
  .background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--main-blue);
    opacity: 0.6;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    // z-index: 9;
  }
  .rightContent {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-5rem, 11rem);
    flex-direction: column-reverse;
    justify-content: center;
    background-color: var(--main-white);
    transition: all 300ms;
    z-index: 9;
    opacity: 0;
    pointer-events: none;
    box-shadow: 3px 4px 140px 1px var(--secondary-gray);
    border-radius: 30px;
    &.opened {
      height: fit-content;
      transform: translate(none, 11rem);
      opacity: 1;
      pointer-events: auto;
      width: fit-content;
    }

    .closeCrossMobile {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -4rem;
      margin-top: -4rem;
      z-index: 1001;
    }
    .sideLogo {
      transform: scale(1.5);
    }
    .logoSeparator {
      border-bottom: 1px solid var(--color-item);
      padding-bottom: var(--spacing-small);
      width: 80%;
      transform: translate(0px, -10px);
    }
    h3 {
      font-family: "Montserrat";
      font-size: var(--font-s);
      font-weight: var(--w-medium);
      color: var(--secondary-blue);
    }
    .mail {
      font-weight: var(--w-extrabold);
    }
    h3.contactoText {
      margin-top: var(--spacing-medium);
      text-align: left;
      width: 80%;
    }
    .backgroundContainer {
      width: 100%;
      .background {
        width: 120px;
        height: 87px;
        float: right;
      }
    }
    .redes {
      width: 80%;
      margin: var(--spacing-extrasmall) 0 var(--spacing-extrasmall) 0;
    }
    .loginText {
      // margin-top: var(--spacing-medium);
      font-weight: var(--w-regular);
      color: var(--secondary-gray);
      transform: translateY(var(--spacing-extrasmall));
    }
    .closeCrossContainer {
      width: 100%;
      height: var(--spacing-extrasmall);
      .closeCross {
        margin-top: var(--spacing-medium);
        margin-right: var(--spacing-medium);
        float: right;
      }
    }
    .perfilMenu {
      margin-top: 0.5rem !important;
      width: 70%;
      span {
        border-radius: 20px;
        font-size: 1.5rem !important;
      }
      .labelProfileMenu {
        display: flex;
        align-items: center;
        padding: 0.7rem;
        box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.15);
        img {
          margin-left: 1rem;
        }
        span {
          margin-left: 0.7rem;
          padding: 0px;
        }
      }
      .labelSolicitudesMenu {
        display: flex;
        margin-top: 0;
        padding: 1rem;
        img {
          margin-left: 0.6rem;
        }
        span {
          margin-left: 0.7rem;
          padding: 0px;
          margin-top: -1.7rem;
        }
      }
    }
    .labelCloseSession {
      width: 75%;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: var(--spacing-small);
    }
  }
  .worksLength {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    background-color: rgba(11, 26, 50, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-white);
    font-size: var(--font-xs);
    font-weight: var(--w-bold);
    transition: all 350ms ease-in-out;
    margin-left: 1rem;
  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to mobile iOS devices */
    .container {
      .rightContent {
        transform: translate(-5rem, 11rem);
        &.opened {
          transform: translate(none, 11rem);
        }
      }
    }
  }
}
