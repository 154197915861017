.searchResultContent {
  padding: 0 7.5rem 0 12.7rem;
  h4 {
    font-size: var(--font-l-1);
    color: var(--main-blue);
    font-weight: var(--w-bold);
    text-align: left;
    margin-top: 0.3em;
  }
  .sendBulkMessage {
    display: flex;
    justify-content: flex-start;
    margin-bottom: var(--spacing-xlarge);
  }
  .bulkButtonContainer {
    position: relative;
    .bulkButton {
      margin-right: 2.5rem;
      &:hover {
        ~ .tooltip {
          display: block;
          opacity: 1;
        }
      }
    }
    .tooltip {
      opacity: 0;
      display: none;
      position: absolute;
      top: -115%;
      z-index: 9;
      width: 300px;
      right: -30%;
      padding: 1.3rem;
      filter: drop-shadow(0px 4px 4px rgba(51, 51, 51, 0.04));
      background: var(--main-white);
      border: 1px solid #f1f1f1;
      border-radius: 4px;
      transition: all 250ms ease-in-out;
      text-align: justify;
      &::before {
        position: absolute;
        top: -9%;
        left: 87%;
        content: "";
        width: 8px;
        height: 8px;
        background-color: var(--main-white);
        border-left: 1px solid #f1f1f1;
        border-top: 1px solid #f1f1f1;
        transform: rotate(45deg);
      }
    }
  }
}

@media screen and (max-width: 835px) {
  .searchResultContent {
    padding: 0 0 1.6rem 0rem;
    margin-top: 0rem;
    > h4 {
      font-size: var(--font-m);
      margin-bottom: 2.5rem;
      border-top: 1px solid rgba(222, 222, 222, 0.4);
      padding-top: 2.5rem;
      margin-left: 2.4rem;
      margin-right: 2.4rem;
    }
    .searchResultGallery {
      padding: 0 2.4rem;
    }
    .sendBulkMessage {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.6rem;
    }
    .bulkButtonContainer {
      width: 100%;
      .bulkButton {
        margin-left: 0em;
        margin-top: 1.5em;
        padding: 0 2.4rem;
      }
      .tooltip {
        top: -110%;
        z-index: 9;
        width: 250px;
        right: 20%;
      }
    }
  }
}
