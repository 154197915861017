.container {
  width: 100vw;
  // max-width: 52.8rem;
  max-width: 60rem;
  height: fit-content;
  max-height: 85vh;
  box-sizing: border-box;
  margin-bottom: 2rem;
  .divScroll {
    outline: none;
    overflow-y: scroll;
    height: fit-content;
    max-height: 60vh;
    border-bottom: 1px solid #ccc;
    padding-bottom: 2rem;
  }
  .info {
    display: flex;
    flex-flow: column wrap;
    overflow-y: scroll;
    section {
      span {
        font-size: 1.5rem;
        font-weight: bold;
        margin-top: 1rem;
      }
      span:nth-child(1) {
        color: rgb(71, 36, 221);
      }
      span:nth-child(2) {
        color: gray;
      }
    }
  }
}

.container .divScroll::-webkit-scrollbar {
  width: 7px;
  height: 5px;
  background-color: rgb(33, 37, 41);
  // border-top-right-radius: 20px;
  // border-bottom-right-radius: 20px;
}

// .container::-webkit-scrollbar-button {
//   height: 20px;
// }

.container .divScroll::-webkit-scrollbar-thumb {
  background-color: gray;
  // border-top-right-radius: 20px;
  // border-bottom-right-radius: 20px;
}

.info::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  background-color: rgb(33, 37, 41);
  // border-top-right-radius: 20px;
  // border-bottom-right-radius: 20px;
}

.info::-webkit-scrollbar-thumb {
  background-color: gray;
  // border-top-right-radius: 20px;
  // border-bottom-right-radius: 20px;
}

// .container::-webkit-scrollbar-track {
//   margin-bottom: 30px;
// }

// .container::-webkit-scrollbar-track-piece:start {
//   margin-top: 30px;
// }

@media screen and (max-width: 835px) {
  .container {
    max-width: 80vw;
  }
}
