.container {
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;
  .searchInput {
    width: 100%;
    height: 5.6rem;
    font-size: var(--font-m);
    background-color: rgba(222, 222, 222, 0.2);
    color: var(--main-blue);
    -webkit-appearance: none;
    padding: 1.6rem;
    border-radius: 4px;
    border: unset;
    font-weight: var(--w-regular);
    position: relative;
    font-family: Open Sans, sans-serif;
    outline: none;
    margin-bottom: var(--spacing-small);
  }
}

// ········· SERVICE CATEGORY ··········· //
.serviceCategory {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-small);
  .serviceCategoryName {
    display: inline;
    line-height: 3.5rem;
    font-size: var(--font-m);
    color: var(--secondary-gray);
    font-weight: var(--w-bold);
    width: 80%;
  }
  .checkboxStyle {
    background-color: var(--main-gray);
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--main-gray);
    border-radius: val(--border-radius);
    max-width: 85%;
    
  }
  .checkboxStyle:hover{
    background-color: var(--color-item-background);
    border: 1px solid var(--color-item);
    color: var(--color-item-background);
    border-radius: val(--border-radius);
  }
}

@media screen and (max-width: 835px) {
  .container {
    max-height: 65vh;
    .searchInput {
      margin-top: var(--spacing-small);
    }
  }
  .serviceCategory{
    .serviceCategoryName{
      
    }
  }
}
