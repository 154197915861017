.profileWorkDoneContainer {
  position: relative;
  .worksWrapper {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 12rem);
    grid-column-gap: var(--spacing-small);
    max-width: 66.4rem;
    overflow: hidden;
    margin-right: 8rem;
    .workItem {
      border-radius: 8px;
      transition: all 350ms ease-in-out;
      width: 12rem;
      height: 12rem;
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
  .chevron {
    position: absolute;
    top: 50%;
    right: 1%;
    transform: translateY(-50%) rotate(-90deg);
    border-radius: 50%;
    display: block;
    width: 5.6rem;
    height: 5.6rem;
    filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.11));
    background: url("../../../assets/svg/down-chevron.svg") no-repeat center
      #fff;
    background-size: 40%;
    cursor: pointer;
  }
}

@media screen and (max-width: 835px) {
  .profileWorkDoneContainer {
    .worksWrapper {
      overflow-x: auto;
      margin-right: 0;
    }
    .chevron {
      display: none;
    }
  }
}
