.container {
  width: 100vw;
  max-width: 52.8rem;
  box-sizing: border-box;
}

@media screen and (max-width: 835px) {
  .container {
    max-width: 80vw;
  }
}
