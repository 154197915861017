.width-100 {
  width: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-just-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-just-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-juststart-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.m-center {
  margin: 0 auto;
}

.pd-10 {
  padding: var(--spacing-small);
}

.pd-20 {
  padding: var(--spacing-medium);
}
.pd-30 {
  padding: var(--spacing-large);
}

.pdv-10 {
  padding: var(--spacing-small) 0;
}
.pdv-20 {
  padding: var(--spacing-medium) 0;
}
.pdv-30 {
  padding: var(--spacing-large) 0;
}

.m-10 {
  margin: var(--spacing-small);
}

.m-20 {
  margin: var(--spacing-small);
}

.m-30 {
  margin: var(--spacing-small);
}

.mv-10 {
  margin: var(--spacing-small) 0;
}

.mv-20 {
  margin: var(--spacing-small) 0;
}

.mv-30 {
  margin: var(--spacing-small) 0;
}
