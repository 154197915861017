@import "~styles";
.profileModal {
  width: 100vw;
  // max-width: 52.8rem;
  max-width: 62.8rem;
  box-sizing: border-box;
}
.textarea {
  background: rgba(222, 222, 222, 0.2);
  border: none;
  border-radius: 4px;
  font-size: var(--font-m);
  @extend .opensans;
  padding: 1.2rem;
  width: 100%;
  min-height: 225px;
  &:focus {
    outline: none;
  }
}
.modalFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-medium);
}

.phoneNumber {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  input {
    width: 100%;
  }
}

.workContent {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 35.5rem;
  overflow: auto;
  .work {
    margin-right: var(--spacing-medium);
    margin-bottom: var(--spacing-medium);
    .workImage {
      width: 14rem;
      height: 14rem;
      border-radius: 8px;
      object-fit: cover;
    }
    .delete {
      cursor: pointer;
      font-size: var(--font-xs);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.8rem;
      font-weight: var(--w-bold);
      &::before {
        content: "";
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        background: url("../../../assets/svg/delete.svg") no-repeat center;
        margin-right: 0.8rem;
        background-size: 100%;
      }
    }
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  max-height: 55vh;
  overflow-y: auto;
  .row {
    display: flex;
    align-items: center;
    margin-bottom: var(--spacing-small);
    position: relative;
  }
  .currency {
    display: flex;
    align-items: center;
    margin-left: auto;
    .inputcurrencyhide {
      position: relative;
    }
    .inputcurrencyhide::after {
      width: 90%;
      height: 100%;
      border-radius: 4px;
      padding: 0.2rem 1rem;
      background-color: rgb(249, 249, 249);
      color: rgba(11, 26, 50, 0.5);
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      content: "-";
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    margin-bottom: 3.2rem;
    position: relative;
  }
  .input {
    font-size: var(--font-m);
    background-color: rgba(222, 222, 222, 0.2);
    color: var(--main-blue);
    -webkit-appearance: none;
    padding: 0.2rem 1rem;
    border-radius: 4px;
    border: unset;
    font-weight: var(--w-regular);
    height: 5.6rem;
    position: relative;
    font-family: Open Sans, sans-serif;
    outline: none;
    pointer-events: auto;
    opacity: 1;
    max-width: 100%;
    margin: 0;
  }
}

.listContainer {
  max-height: 60vh;
  overflow: auto;
}
.optionsContainer {
  display: flex;
  flex-direction: column;
  .optionsHeader {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--spacing-small);
    margin-top: var(--spacing-medium);
    // border-bottom: 1px solid rgba(11, 26, 50, 0.1);
    .optionName {
      font-size: var(--font-m);
      color: var(--secondary-gray);
      font-weight: var(--w-regular);
    }
    .optionsOpenHandler {
      display: flex;
      align-items: center;
      cursor: pointer;
      p {
        font-size: var(--font-m);
        color: var(--secondary-blue);
      }
      .icon {
        cursor: pointer;
        width: 1.2rem;
        height: 1.2rem;
        fill: var(--secondary-blue);
        margin-left: 1rem;
        transition: all 350ms ease-in-out;
        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }
  .subServiceList {
    .subService {
      display: flex;
      align-items: center;
      margin: var(--spacing-small) 0;
      .editValues {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
      }
    }
  }
}

.valorationModal {
  .body {
    max-height: 65vh;
    overflow: auto;
  }
  .valorationItem {
    display: flex;
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      cursor: auto;
      .box {
        background-color: rgba(222, 222, 222, 0.4);
        border: 1px solid #dedede;
        &.checked {
          opacity: 0.2;
        }
      }
    }
    .box {
      height: 2.4rem;
      width: 2.4rem;
      background-color: var(--main-white);
      border-radius: 4px;
      border: 1px solid #d4d4d4;
      transition: background-color 350ms;
      margin-right: var(--spacing-medium);
      margin-top: var(--spacing-medium);
      &.checked {
        background: url("../../../assets/svg/check.svg") no-repeat center;
        background-color: var(--secondary-blue);
        border: none;
      }
    }
  }
  .buttonContent {
    margin-top: var(--spacing-medium);
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 835px) {
  .profileModal {
    max-width: 85vw;
  }
  .modalHeader {
    margin-bottom: 0;
  }
  .workContent {
    margin-top: 3.2rem;
  }
  .infoContainer {
    overflow-x: hidden;
    padding-top: var(--spacing-small);
    .row,
    .column {
      margin-bottom: var(--spacing-small);
    }
    .row {
      flex-wrap: wrap;
    }
    .currency {
      margin-top: 0.8rem;
      margin-left: 0;
      justify-content: space-between;
      width: 100%;
    }
  }
}
